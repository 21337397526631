import React from 'react';
import { Box, List, ListItem, ListItemText, IconButton, Typography, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';

type Document = string;

interface DocumentListProps {
  documents: Document[];
  onDelete: (url: Document) => Promise<void>;
  onDownload: (url: Document) => Promise<void>;
  isLoading: boolean;
  error: string | null;
}

const DocumentList: React.FC<DocumentListProps> = ({ documents, onDelete, onDownload, isLoading, error }) => {
  return (
    <Box>
      {error && <Typography color="error">{error}</Typography>}
      {documents.length === 0 ? (
        <Box textAlign="center" mt={4}>
          <Typography variant="h6">Aucun document disponible</Typography>
          <Typography variant="body1">Veuillez télécharger des fichiers pour commencer.</Typography>
        </Box>
      ) : (
        <List>
          {documents.map((document, index) => (
            <ListItem key={index}>
              <ListItemText primary={document} />
              <IconButton disabled={isLoading} edge="end" aria-label="download" onClick={() => onDownload(document)}>
                <DownloadIcon />
              </IconButton>
              <IconButton disabled={isLoading} edge="end" aria-label="delete" onClick={() => onDelete(document)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}
      {isLoading && (
        <Box textAlign="center" mt={4}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default DocumentList;
