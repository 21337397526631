import React, { useState } from 'react';
import { useDeleteBiologicalDataMutation, useGetBiologicalDataMutation, usePostBiologicalDataMutation } from '../../store/api';
import { Box, Button } from '@mui/material';
import { FormData, Patient } from '../../types/types';
import { useSelector } from 'react-redux';
import { patientSelector } from '../../store/selectors';
import { setPatient } from '../../store/slices';
import { useDispatch } from 'react-redux';
import FileModal from '../modal/file-modal';
import DocumentList from '../list/document-list';

const MAX_FILE_SIZE = 4.2 * 1024 * 1024;

interface S3FileUploadComponentProps {
  patientId: string;
  formData: FormData;
  setFormData: (formData: FormData) => void;
}

const S3FileUploadComponent: React.FC<S3FileUploadComponentProps> = ({ patientId, formData, setFormData }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [upload] = usePostBiologicalDataMutation();
  const [getBiological] = useGetBiologicalDataMutation();
  const [deleteBiological] = useDeleteBiologicalDataMutation();
  const patient = useSelector(patientSelector).patient;

  const date = new Date();

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  async function readFileAsBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
  

  const handleConfirm = async (files: File[]) => {
    try {
      setError(null);
      setIsLoading(true);
      const newDocuments: string[] = [];

      for (const file of files) {
        const base64File = await readFileAsBase64(file);
        const base64Content = base64File.split(',')[1];
        const extension = file.name.split('.');
        const url = await upload({
          id: patientId,
          file_content: base64Content,
          file_name: `${patientId}.${extension[extension.length - 1]}`,
        }).unwrap();
      
        newDocuments.push(url);
      }

      setFormData({
        ...formData,
        ['7d8ad49b-2af5-495d-9f08-357e8ef57050']: 'true',
        ['24e559f6-f42b-49be-b30c-43adf5b8fd90']: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,
      });
      const patientAttribut : Patient = {
        ...patient,
        biological_file_name: [...patient.biological_file_name, ...newDocuments]
      }
      dispatch(setPatient(patientAttribut));
    } catch (err) {
      setError('Une erreur est survenue lors du téléchargement');
      if (err instanceof Error) {
        console.error("Upload Error", err.message);
      } else {
        console.error("An unknown error occurred", err);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleGetBiological = async (item:string) => {
    try {
      setError(null);
      setIsLoading(true);
      const response = await getBiological({id: patientId, file_name: item}).unwrap();
      window.open(response.url, '_blank');
    } catch (err) {
      setError('Une erreur est survenue lors du téléchargement');
      if (err instanceof Error) {
        console.error("Upload Error", err.message);
      } else {
        console.error("An unknown error occurred", err);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleDeleteBiological = async (file_name: string) => {
    try {
      setError(null);
      setIsLoading(true);
      await deleteBiological({id: patientId, file_name: file_name}).unwrap();
      const patientAttribut : Patient = {
        ...patient,
        biological_file_name: patient.biological_file_name.filter(item => item !== file_name)
      }
      dispatch(setPatient(patientAttribut));
      setFormData({
        ...formData,
        ['7d8ad49b-2af5-495d-9f08-357e8ef57050']: 'false',
        ['24e559f6-f42b-49be-b30c-43adf5b8fd90']: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,
      });
    } catch (err) {
      setError('Une erreur est survenue lors du téléchargement');
      if (err instanceof Error) {
        console.error("Upload Error", err.message);
      } else {
        console.error("An unknown error occurred", err);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box sx={{backgroundColor: 'white', borderRadius: 10, margin: 5, padding: 5, boxShadow: 1}}>
      <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <h1>Gestion des documents biologiques</h1>
        <Button variant="contained" onClick={handleOpenModal} disabled={patient.biological_file_name.length > 4}>
        Télécharger des fichiers
        </Button>
      </Box>
      <FileModal open={modalOpen} onClose={handleCloseModal} onConfirm={handleConfirm} maxFiles={5 - patient.biological_file_name.length} isLoading={isLoading} error={error} />
      <DocumentList documents={patient.biological_file_name} onDelete={handleDeleteBiological} onDownload={handleGetBiological} isLoading={isLoading} error={error}/>
    </Box>
  );
};

export default S3FileUploadComponent;
