import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useGetDocumentMutation } from '../store/api';
import { useSelector } from 'react-redux';
import { adminSelector } from '../store/selectors';
import { siteSelector } from '../store/selectors/siteSelector';
import { AdminRole } from '../types/types';

export default function DocumentPage() {
  const [selectedDocument, setSelectedDocument] = useState<string>('1');
  const user = useSelector(adminSelector);
  const [
    getDocument,
    { isLoading: isLoadingGetDocument },
  ] = useGetDocumentMutation();
  const [soin, setSoin] = useState<boolean>(false);
  const [recherche, setRecherche] = useState<boolean>(false);
  const sites = useSelector(siteSelector).sites.filter( site => user.admin_role === AdminRole.Admin ? true : user.site.includes(site.id));
  useEffect(() => {
    sites.map(value => {
      if(value.type === 'CIC' || value.type === 'centre de prevention'){
        setRecherche(true);
      } else if (value.type === 'soins'){
        setSoin(true);
      }
    })
  }, [sites])
  
  const pdfFileList = [
    {
      id: '1',
      label: "NIFC recherche",
      description: "Note d’information pour les volontaires témoins (i.e. hors 'soins').",
      file: `2023-A02494-41_NIFC_recherche_version_finale.pdf`,
      available: recherche,
      site: 'all',
    },
    {
      id: '2',
      label: "NIFC soins",
      description: "Note d’information pour les volontaires patients recrutés dans les centres de santé dans le cadre de soins.",
      file: `2023-A02494-41_NIFC_soins_version_finale.pdf`,
      available: soin,
      site: 'all',
    },
    {
      id: '3',
      label: 'Guide eCRF et outil de gestion',
      description: "Document pour expliquer le remplissage d’eCRF et l’outil de gestion de l’étude clinique « PINEAPPL-data ».",
      file: `2023-A02494-41_GUIDE_ECRF_v1_20240118_PINEAPPL2023.pdf`,
      available: true,
      site: 'all',
    },
    {
      id: '6',
      label: 'Affiche Bordeaux soins',
      description: "Affiche de recrutement à disséminer ou utiliser localement.",
      file: `Affiche_bordeaux_soins.pdf`,
      available: soin,
      site: 'bordeaux',
    },
    {
      id: '7',
      label: 'Affiche Colmar soins',
      description: "Affiche de recrutement à disséminer ou utiliser localement.",
      file: `Affiche_colmar_soins.pdf`,
      available: soin,
      site: 'colmar',
    },
    {
      id: '8',
      label: 'Affiche Dijon CIC',
      description: "Affiche de recrutement à disséminer ou utiliser localement.",
      file: `Affiche_dijon_CIC.pdf`,
      available: recherche,
      site: 'dijon',
    },
    {
      id: '9',
      label: 'Affiche Lille CIC',
      description: "Affiche de recrutement à disséminer ou utiliser localement.",
      file: `Affiche_lille_PASTEUR_CIC.pdf`,
      available: recherche,
      site: 'lille',
    },
    {
      id: '10',
      label: 'Affiche Marseille 1',
      description: "Affiche de recrutement à disséminer ou utiliser localement.",
      file: `Affiche_marseille_1.pdf`,
      available: recherche,
      site: 'marseille-2',
    },
    {
      id: '11',
      label: 'Affiche Marseille 2',
      description: "Affiche de recrutement à disséminer ou utiliser localement.",
      file: `Affiche_marseille_2.pdf`,
      available: soin,
      site: 'marseille-1',
    },
    {
      id: '12',
      label: 'Affiche Paris CIC',
      description: "Affiche de recrutement à disséminer ou utiliser localement.",
      file: `Affiche_paris_CIC.pdf`,
      available: recherche,
      site: 'paris-2',
    },
    {
      id: '13',
      label: 'Affiche Paris soins',
      description: "Affiche de recrutement à disséminer ou utiliser localement.",
      file: `Affiche_paris_soins.pdf`,
      available: soin,
      site: 'paris-1',
    },
    {
      id: '14',
      label: 'SOP sérum',
      description: "Procédure opérationnelle standard (SOP) pour l'extraction du sérum et la préparation des alicots à partir du tube de collecte supplémentaire (tube rouge sec gel), ainsi que la fiche qualité à remplir.",
      file: `SOP_serum.pdf`,
      available: true,
      site: 'all',
    }
  ]

  const handlePrintPDF = async (url: string) => {
    setSelectedDocument(url);
    const result = await getDocument({filename: url}).unwrap();
    window.open(result.url, '_blank');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
      }}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          backgroundColor: 'white',
          boxShadow: 1,
          borderRadius: 5,
          padding: 5,
        }}
      ><Typography variant='h3'>Document à télécharger</Typography></Box>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          backgroundColor: 'white',
          boxShadow: 1,
          borderRadius: 5,
          padding: 3,
          margin: 3,
        }}
      >
        <Typography sx={{textAlign: 'center', marginBottom: 2}} variant='h6'>Bienvenue sur la Page de Téléchargement des Documents Officiels pour l&apos;Étude</Typography>
        <Typography sx={{textAlign: 'center'}} variant='body1'>Nous sommes ravis de vous offrir un accès direct et sécurisé à tous les documents officiels nécessaires pour vos études. Sur cette page, vous trouverez une variété de ressources téléchargeables conçues pour faciliter votre parcours.</Typography>
      </Box>
      <Box sx={{width: '100%', flexWrap: 'wrap', alignItems: 'stretch',}}>
        <Grid container spacing={2}>
          {
            pdfFileList.filter(value => value.available === true).filter(value => 
              user.admin_role === AdminRole.Admin ? true : user.site.includes(value.site) || value.site === 'all'
            ).map(pdfFile => (
              <Grid justifyContent="center"
                display={'flex'}
                flexGrow={1}
                alignItems="stretch" key={pdfFile.id} item  xs={12} sm={6} lg={4}>
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    backgroundColor: 'white',
                    boxShadow: 1,
                    borderRadius: 5,
                    padding: 5
                  }}
                >
                  <Typography sx={{textAlign: 'center'}} variant='h4'>{pdfFile.label}</Typography>
                  <Box sx={{display: 'flex',  flex: 1}}>
                    <Typography sx={{marginY: 3}}>{pdfFile.description}</Typography>
                  </Box>
                  {
                    selectedDocument === pdfFile.file && isLoadingGetDocument ? (
                      <Box sx={{display: 'flex',  flex: 1, alignItems: 'center', justifyContent: 'center'}}><CircularProgress /></Box>
                    ) : (
                      <Button variant="contained" color='success' onClick={() => handlePrintPDF(pdfFile.file)} endIcon={<DownloadIcon />}>Télécharger</Button>
                    )
                  }
                  
                </Box>
              </Grid>
            ))
          }
        </Grid>
      </Box>
      
    </Box>
  );
}
