import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Checkbox, FormControlLabel, IconButton, LinearProgress, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { CheckBox, Edit } from '@mui/icons-material';
import { patientSelector } from '../../store/selectors';
import { Patient, PatientStatus } from '../../types/types';


export default function PatientsExpiredDashboard() {
  const navigate = useNavigate();
  const patients = useSelector(patientSelector).patients;
  const width = window.innerWidth;
  const [patientType, setPatientType] = React.useState<'expired' | 'unactive'>('unactive');

  const [selectedPatient, setSelectedPatient] = React.useState<Patient[]>([]);

  const [filter, setFilter] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilter(event.target.value);
  };


  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: width * 0.1 },
    { field: 'site', headerName: 'Clinique', width: width * 0.06  },
    {
      field: 'timestamp_creation',
      headerName: 'Date de création',
      width: width * 0.09 ,
      renderCell: (params) => {
        const date = new Date(params.value * 1000);
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: 'UTC'
        };
        const formattedDate = date.toLocaleString('fr-FR', options);
        return (
          <div>
            <span>{formattedDate}</span>
          </div>
        );
      }
    },
    { field: 'step', headerName: 'Progression', width: width * 0.1,
      renderCell: (params) => {
        // Composant personnalisé à afficher dans la cellule
        return (
          <Box sx={{width: '100%',display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <LinearProgress sx={{width: '70%', marginRight: 4}} variant="determinate" value={patients.find(value => value.id === params.id)?.patient_status === PatientStatus.Finished ? 100 : Number(params.value) / 7 * 100} />
            <h6 style={{ margin: '0' }}>{patients.find(value => value.id === params.id)?.patient_status === PatientStatus.Finished ? 100 : (Number(params.value) / 7 * 100).toFixed(0)}%</h6>
          </Box>
        );
      }
    },
    { field: 'patient_status', headerName: 'Statut', width: width * 0.09,
      renderCell: (params) => {
        let color;
        switch (params.value) {
        case PatientStatus.Selected:
          color= 'yellow';
          break;
        case PatientStatus.Included:
          color= 'green';
          break;
        case PatientStatus.Finished:
          color= 'grey';
          break;
        case PatientStatus.Deleted:
          color = 'red';
          break;
        case PatientStatus.DropOut:
          color= 'orange';
          break;
        default:
          color= 'purple'
        }
        // Composant personnalisé à afficher dans la cellule
        return (
          <Box sx={{width: '100%',display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Box sx={{
              width: '10px',
              height: '10px',
              backgroundColor: color,
              borderRadius: '50%'
            }} />
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 1 }}>
              <h6 style={{ margin: '0' }}>{params.value}</h6>
            </Box>
          </Box>
        );
      } 
    },
    {
      field: 'inclusion_timestamp',
      headerName: "Date d'inclusion",
      width: width * 0.1 ,
      renderCell: (params) => {
        const date = new Date(params.value);
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: 'UTC'
        };
        const formattedDate = date.toLocaleString('fr-FR', options);
        return (
          <div>
            <span>{formattedDate}</span>
          </div>
        );
      }
    },
    {
      field: 'expiration_timestamp',
      headerName: "Date d'expiration",
      width: width * 0.1 ,
      renderCell: (params) => {
        const date = new Date(params.value);
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: 'UTC'
        };
        const formattedDate = date.toLocaleString('fr-FR', options);
        return (
          <div>
            <span>{formattedDate}</span>
          </div>
        );
      }
    },
    {
      field: 'sample_validate',
      headerName: "Selles",
      width: width * 0.07 ,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.value === 'true' ? 'oui' : 'non'}</span>
          </div>
        );
      }
    },
    {
      field: 'r24_validate',
      headerName: "Validation R24",
      width: width * 0.07 ,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.value === 'true' ? 'oui' : 'non'}</span>
          </div>
        );
      }
    },
    {
      field: 'report',
      headerName: "Rapport",
      width: width * 0.07 ,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.value === 'true' ? 'oui' : 'non'}</span>
          </div>
        );
      }
    },
    {
      field: 'modify',
      headerName: 'Modifier',
      width: width * 0.05,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => navigate('/root/patient/' + params.id)}><Edit/></IconButton>
        );
      }
    },
  ];

  useEffect(() => {
    const time = new Date().getTime();
    if(patientType === 'expired') {
      setSelectedPatient(patients.filter(patient => time > patient.expiration_timestamp && (patient.r24_validate === undefined || patient.r24_validate === "false") && patient.report === false && patient.sample_validate === "false" && patient.patient_status === PatientStatus.Included ).sort((a, b) => b.timestamp_creation - a.timestamp_creation))
    } else {
      setSelectedPatient(patients.filter(patient => time > patient.inclusion_timestamp + 172800 && time < patient.expiration_timestamp && patient.report === false).sort((a, b) => b.timestamp_creation - a.timestamp_creation))
    }
  }, [patientType, patients])

  const handlePatientTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.name === 'expired') {
      setPatientType('expired');
    } else {
      setPatientType('unactive');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10
      }}>
      <h1>Patients inactifs/expirés</h1>
      <Box sx={{marginY: 2, display: 'flex', align: 'center', alignItems: 'center'}}>
        <TextField size='small' id="standard-basic" label="Chercher un patient" variant="outlined" onChange={handleChange} />
        <FormControlLabel
          sx={{marginLeft: 2}}
          label="Patient expiré"
          control={
            <Checkbox
              name='expired'
              checked={patientType === 'expired'}
              onChange={handlePatientTypeChange}
            />
          }
        />
        <FormControlLabel
          label="Patient inactif (Patient n&apos;ayant pas répondu au questionnaire dans les deux premiers jours)"
          control={
            <Checkbox
              name='unactive'
              checked={patientType === 'unactive'}
              onChange={handlePatientTypeChange}
            />
          }
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          backgroundColor: 'white',
          boxShadow: 1,
          borderRadius: 5,
          paddingBottom: 2,
        }}
      ><DataGrid
          rows={patients.length > 0 ? (filter !== '' ? selectedPatient.filter(patient => patient.id.includes(filter)) : selectedPatient) : []}
          columns={columns}
          getRowClassName={(params) => params.row.report ? "{backgroundColor: 'red'}" : "{backgroundColor: 'blue'}"}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          sx={{
            height: patients.length === 0 ? 400 : 'auto',
            border: 'none'
          }}
        />
      </Box>
    </Box>
  );
}
