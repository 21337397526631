import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Data, ResponseKitData } from '../../types/types';
import { DataState } from '../type';

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    data: [],
    kitData: undefined,

  } as DataState,
  reducers: {
    setKitData(state, action: PayloadAction<ResponseKitData>) {
      state.kitData = action.payload;
    },
    setData(state, action: PayloadAction<Data[]>) {
      state.data = action.payload;
    },
    updateData(state, action: PayloadAction<Data>) {
      const existingData = state.data.find(
        (element) => element.question_id === action.payload.question_id
      );
      const updatedDatas = existingData
        ? state.data.map((element) =>
          element.question_id === action.payload.question_id
            ? {
              question_id: action.payload.question_id,
              answer: action.payload.answer,
              patientId: action.payload.patientId,
              createTimestamp: action.payload.createTimestamp
            }
            : element
        )
        : [
          ...state.data,
          {
            question_id: action.payload.question_id,
            answer: action.payload.answer,
            patientId: action.payload.patientId,
            createTimestamp: action.payload.createTimestamp
          },
        ];
      state.data = updatedDatas;
    },
  },
});

export const { setData, updateData, setKitData } = dataSlice.actions;

export const dataReducer = dataSlice.reducer;
