/* eslint-disable @typescript-eslint/ban-types */
import { createApi } from '@reduxjs/toolkit/query/react';
import { Site } from '../../types/types';
import { baseQueryWithReauth } from '../../utils/api';

export const siteApi = createApi({
  reducerPath: 'siteApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getSite: builder.mutation<
      Site[],
      void
    >({
      query: () =>
        `/admin/site`,
    }),
  }),
});

export const {
  useGetSiteMutation,
} = siteApi;
