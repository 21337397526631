import React, { useState } from 'react';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Button,
  IconButton,
  Link,
} from '@mui/material';
import { Question, PatientPageLoaderData, FormData, PatientGroup, FormError } from '../../types/types';
import { usePostDataMutation } from '../../store/api';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { updateData } from '../../store/slices';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { isAdminSelector, isPatientSelector } from '../../store/selectors';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QueryModal from '../modal/query-modal';

type FormProps = {
  questions: Question[];
  formData: FormData;
  setFormData: (formData: FormData) => void;
  formError: FormError;
  setFormError: (formError: FormError) => void;
  disable: boolean;
  patientGroup: PatientGroup;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export async function loader({ params }: LoaderFunctionArgs) {
  const surveyId = params.surveyId;
  return { surveyId };
}

const DynamicForm: React.FC<FormProps> = ({ questions, formData, setFormData, formError, setFormError, disable, patientGroup, loading, setLoading }) => {
  const [
    postData,
  ] = usePostDataMutation();
  const loaderData = useLoaderData() as PatientPageLoaderData;
  const dispatch = useDispatch();
  const admin = useSelector(isAdminSelector);
  const patient = useSelector(isPatientSelector).patient;
  const [openQueryModal, setOpenQueryModal] = useState(false);
  const [questionQueryModal, setQuestionQueryModal] = useState<Question>();

  const handleValidate = (question: Question) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let error = '';
    try {
      const target = event.target as HTMLInputElement;
      const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'date'
          ? target.value
          : target.value;
      if (question.type === "number" ) {
        const numericValue = Number(value);
        if (
          !isNaN(numericValue) &&
          patientGroup === PatientGroup.ClinicallyAtRisk &&
          ((question.limitMaxRisk !== undefined && numericValue > parseFloat(question.limitMaxRisk)) ||
            (question.limitMinRisk !== undefined && numericValue < parseFloat(question.limitMinRisk)))
        ) {
          error = 'En dehors des valeurs attendues (min: ' + question.limitMinRisk + ', max: ' + question.limitMaxRisk + ')';
          setFormError({
            ...formError,
            [question.id]: error,
          });
        } else if (
          !isNaN(numericValue) &&
          patientGroup === PatientGroup.NoClincallyAtRisk &&
          ((question.limitMaxNoRisk !== undefined && numericValue > parseFloat(question.limitMaxNoRisk)) ||
            (question.limitMinNoRisk !== undefined && numericValue < parseFloat(question.limitMinNoRisk)))
        ) {
          error = 'En dehors des valeurs attendues (min: ' + question.limitMinNoRisk + ', max: ' + question.limitMaxNoRisk + ')';
          setFormError({
            ...formError,
            [question.id]: error,
          });
        } else {
          setFormError({
            ...formError,
            [question.id]: '',
          });
        }
      }
      dispatch(updateData({
        patientId: loaderData.patientId,
        createTimestamp: 1,
        question_id: question.id,
        answer: String(value),
      }));
      const postDataAsync = async () => {
        try {
          const result = await postData({id: loaderData.patientId, data: {
            question_id: question.id,
            answer: String(value)
          }}).unwrap()
          if(result.message === 'Kit not exist'){
            setFormError({
              ...formError,
              [question.id]: "Ce kit n'éxiste pas",
            });
          }
          else if(result.message === 'Kit used'){
            setFormError({
              ...formError,
              [question.id]: "Ce kit est déjà utilisé",
            });
          } else {
            setFormError({
              ...formError,
              [question.id]: '',
            });
          }
        } finally {
          setLoading(false);
        }
        
      }
      if(question.id === 'b0254ef0-caee-4524-8a23-4c19fcd73aa3') {
        setLoading(true);
        postDataAsync();
      } else {
        postData({id: loaderData.patientId, data: {
          question_id: question.id,
          answer: String(value)
        }}).unwrap()
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (question: Question) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let error = '';
    try {
      const target = event.target as HTMLInputElement;
      const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'date'
          ? target.value
          : target.value;
      if (question.type === "boolean"){
        if(value === false) {
          error = 'Non validé'
          setFormError({
            ...formError,
            [question.id]: error,
          });
        } else {
          setFormError({
            ...formError,
            [question.id]: '',
          });
        }
        postData({id: loaderData.patientId, data: {
          question_id: question.id,
          answer: String(value)
        }})
        dispatch(updateData({
          patientId: loaderData.patientId,
          createTimestamp: 1,
          question_id: question.id,
          answer: String(value),
        }));
      }
      setFormData({
        ...formData,
        [question.id]: value,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const groupedQuestions: { [category: string]: Question[] } = {};
  questions.forEach((question) => {
    if (!groupedQuestions[question.category]) {
      groupedQuestions[question.category] = [];
    }
    groupedQuestions[question.category].push(question);
  });

  return (
    <form>
      <Box sx={{backgroundColor: 'white', borderRadius: 10, margin: 5, padding: 5, boxShadow: 1}}>
        <Box style={{ gap: '16px' }}>
          {Object.entries(groupedQuestions).map(([category, categoryQuestions]) => (
            <div key={category} style={{marginBottom: 30}}>
              <Typography sx={{marginY: 2}} variant="h5">{category}</Typography>
              <Box style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
                {categoryQuestions.sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort)).map((question) => (
                  <Box key={question.id}>
                    {question.type === 'boolean' && (
                      <Box>
                        <FormControlLabel
                          disabled={disable || question[admin.admin_role] === "False"}
                          sx={{color: formError[question.id] ? 'red' : 'black'}}
                          control={
                            <Checkbox
                              sx={{color:formError[question.id] !== '' && formError[question.id] !== undefined ? 'red' : 'black'}}
                              checked={Boolean(formData[question.id])}
                              onChange={handleChange(question)}
                            />
                          }
                          label={question.label}
                        />
                        {["R24-1"].includes(question.label) && <Link target="_blank" href={patient['r24-1']}>Vérifier</Link>}
                        {["R24-2"].includes(question.label) && <Link target="_blank" href={patient['r24-2']}>Vérifier</Link>}
                        {["R24-3"].includes(question.label) && <Link target="_blank" href={patient['r24-3']}>Vérifier</Link>}
                        {formError[question.id] && <Typography variant='subtitle1' sx={{marginLeft: 2, color: 'red'}}>{formError[question.id]}</Typography>}
                      </Box>
                    )}
                    {question.type === 'number' && (
                      <Box>
                        <Box display="flex" alignItems="center">
                          <TextField
                            error={formError[question.id] !== '' && formError[question.id] !== undefined}
                            disabled={disable || question[admin.admin_role] === "False"}
                            type="number"
                            label={question.label}
                            value={formData[question.id] || ''}
                            onChange={handleChange(question)}
                            onBlur={handleValidate(question)}
                          />
                          
                          {question.unit && question.unit !== 'nan' && <Typography sx={{marginLeft: 2}}>{question.unit}</Typography>}
                        </Box>
                        {formError[question.id] && <Typography variant='subtitle1' sx={{marginLeft: 2, color: 'red'}}>{formError[question.id]}</Typography>}
                      </Box>
                    )}
                    {question.type === 'string' && (
                      <Box>
                        <Box display="flex" alignItems="center">
                          <TextField
                            error={formError[question.id] !== '' && formError[question.id] !== undefined}
                            disabled={disable || question[admin.admin_role] === "False"}
                            type="text"
                            label={question.label}
                            value={formData[question.id] || ''}
                            onChange={handleChange(question)}
                            onBlur={handleValidate(question)}
                          />
                          {question.unit && question.unit !== 'nan' && <Typography sx={{marginLeft: 2}}>{question.unit}</Typography>}
                        </Box>
                        {formError[question.id] && <Typography variant='subtitle1' sx={{marginLeft: 2, color: 'red'}}>{formError[question.id]}</Typography>}
                      </Box>
                    )}
                    {question.type === 'date' && (
                      <Box>
                        <TextField
                          error={formError[question.id] !== '' && formError[question.id] !== undefined}
                          disabled={disable || question[admin.admin_role] === "False"}
                          type="date"
                          label={question.label}
                          value={formData[question.id] || ''}
                          onChange={handleChange(question)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={handleValidate(question)}
                        />
                        {formError[question.id] && <Typography variant='subtitle1' sx={{marginLeft: 2, color: 'red'}}>{formError[question.id]}</Typography>}
                      </Box>
                    )}
                    {question.description && question.description != "nan" && (
                      <Box style={{ marginLeft: 3, color: disable ? 'grey' : 'black' }}>{question.description.split('/n').map((text,index) => (
                        <Typography sx={{color: 'grey'}} key={index}>{text}</Typography>
                      ))}</Box>
                    )}
                    {
                      question.id === 'b0254ef0-caee-4524-8a23-4c19fcd73aa3' && <Button sx={{marginTop: 1}} variant='contained'>Verifier</Button>
                    }
                    <IconButton onClick={() => {setOpenQueryModal(true); setQuestionQueryModal(question)}}><HelpOutlineIcon /></IconButton>
                  </Box>
                ))}
              </Box>
            </div>
          ))}
        </Box>
        <QueryModal open={openQueryModal} handleClose={() => setOpenQueryModal(false)} question_id={questionQueryModal?.id ?? '0'} question_answer={String(formData[questionQueryModal?.id ?? '0']) } question={questionQueryModal?.label ?? 'inconnu'} />
      </Box>
    </form>
  );
};

export default DynamicForm
