import React from 'react';
import { Card, CardContent, Typography, Button, Grid, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { AdverseEvent } from '../../types/types';

interface AdverseEventItemProps {
  event: AdverseEvent;
  onEdit: (event: AdverseEvent) => void;
}

const eventActionItems = [
  { value: "0", label: "Aucune" },
  { value: "1", label: "Modification d’un traitement en cours" },
  { value: "2", label: "Nouveau traitement concomitant" },
  { value: "3", label: "Hospitalisation" },
  { value: "4", label: "Arrêt de l’étude" }
];

const severityItems = [
  { value: "0", label: "Aucune" },
  { value: "1", label: "Légère" },
  { value: "2", label: "Modérée" },
  { value: "3", label: "Sévère" }
];

const AdverseEventItem: React.FC<AdverseEventItemProps> = ({ event, onEdit }) => {
  return (
    <Card variant="outlined" sx={{ marginBottom: 3 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <Box sx={{display: 'flex'}}>
              <Typography sx={{marginRight: 3}} variant="body1" color="textSecondary">
              Date de début: {event.start_date}
              </Typography>
              <Typography variant="body1" color="textSecondary">
              Date de fin: {event.end_date !== '' ? event.end_date : 'En cours'}
              </Typography>
            </Box>
            <Typography variant="h6" component="div">
              {event.description}
            </Typography>
            <Box sx={{display: 'flex'}}>
              <Typography sx={{marginRight: 3}} variant="body2" color="textSecondary">
              Sévérité: {severityItems.find(value => value.value === event.severity)?.label}
              </Typography>
              <Typography variant="body2" color="textSecondary">
              Action prise: {eventActionItems.find(value => value.value === event.event_action)?.label}
              </Typography>
            </Box>
            <Box sx={{display: 'flex'}}>
              <Typography sx={{marginRight: 3}} variant="body2" color="textSecondary">
              Type: {event.event_type}
              </Typography>
              <Typography variant="body2" color="textSecondary">
              Relation avec l&apos;étude: {event.linked}
              </Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
              Traitement: {event.treatment}
            </Typography>
            <Typography variant="body2" sx={{marginTop: 2}} color="black">
              Fait à {new Date(event.timestamp * 1000 ).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} container justifyContent="flex-end" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => onEdit(event)}
            >
              Modifier
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AdverseEventItem;
