import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Box, Button, Card, CircularProgress, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { LoaderFunctionArgs, useLoaderData, useNavigate } from 'react-router-dom';
import { AdminRole, AdverseEvent, Patient, PatientGroup, PatientPageLoaderData, PatientStatus } from '../types/types';
import { useDeletePatientMutation, useGetDataByPatientMutation, useGetDocumentMutation, useGetPatientsMutation, useGetReportMutation, usePostTransferToTouvaMutation, usePutPatientMutation, useResetPasswordMutation } from '../store/api';
import { useDispatch } from 'react-redux';
import ProgressSteps from '../components/progress/progress-steps';
import { setData, setPatient, setPatients } from '../store/slices';
import { Colors } from '../styles/colors';
import { useSelector } from 'react-redux';
import { adminSelector, dataSelector, isPatientSelector } from '../store/selectors';
import { siteSelector } from '../store/selectors/siteSelector';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import TuneIcon from '@mui/icons-material/Tune';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ErrorIcon from '@mui/icons-material/Error';
import { useAppContext } from '../components/authentication/account';
import HandleLoadingError from '../components/handle-loading-error/handle-loading-error';
import { CheckCircle } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import CopyButton from '../components/button/copy-button';
import WarningIcon from '@mui/icons-material/Warning';
import { useGetAdverseEventMutation } from '../store/api/adverseEventApi';
import { setAdverseEvent } from '../store/slices/adverseEventSlice';
import { adverseEventSelector } from '../store/selectors/adverseEventSelector';
import AdverseEventModal from '../components/modal/adverse-event-modal';
import AdverseEventItem from '../components/card/adverse-event-item';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import { PDFDocument } from 'pdf-lib';

function convertirTimestamp(timestamp: number) {
  // Créer un nouvel objet Date en utilisant le timestamp
  const date = new Date(timestamp);

  // Extraire les parties de la date (année, mois et jour)
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // +1 car les mois commencent à 0
  const day = ("0" + date.getDate()).slice(-2);

  // Créer la chaîne de date dans le format YYYY-MM-DD
  const formattedDate = year + "-" + month + "-" + day;

  return formattedDate;
}

export async function loader({ params }: LoaderFunctionArgs) {
  const surveyId = params.surveyId;
  return { surveyId };
}

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column'
};

export default function PatientPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loaderData = useLoaderData() as PatientPageLoaderData;
  const { setIsError } = useAppContext();
  const [navigationValue, setNavigationValue] = React.useState<number>(1);
  const [formData, setFormData] = React.useState<Patient>({
    id: '',
    patient_status: PatientStatus.Selected,
    phase: '',
    step: '',
    site: '',
    patient_group: PatientGroup.ClinicallyAtRisk,
    admin_id: '',
    timestamp_creation: 0,
    rank: 0,
    sex: '',
    birthdate: '',
    bmi: 0,
    note: '',
    report_problem: '',
    report: false,
    inclusion_timestamp: 0,
    expiration_timestamp: 0,
    patient_part_finished: '',
    r24_validate: '',
    sample_validate: '',
    anti_lipid: null,
    antihypertensives: null,
    anti_obesogens: null,
    menopause_status: null,
    diet: null,
    nursing: null,
    transferred: false,
    biological_file_name: [],
    "r24-1": "",
    "r24-2": "",
    "r24-3": "",
    finished_timestamp: undefined
  });
  
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const patient = useSelector(isPatientSelector).patient;
  const sites = useSelector(siteSelector).sites;
  const admin = useSelector(adminSelector);
  const [openModal, setOpenModal] = React.useState(false);
  const [password, setPassword] = useState<string>('');
  const [optionModalFunction, setOptionModalFunction] = useState<() => Promise<void>>();
  const data = useSelector(dataSelector).data;
  const adverseEvent = useSelector(adverseEventSelector).adverseEvent;
  const [isLoadingResetPassword2, setIsLoadingResetPassword2] = React.useState(false);

  const [openAdverseEventModal, setOpenAdverseEventModal] = React.useState(false);
  const [adverseEventPutForModal, setAdverseEventPutForModal] = React.useState<AdverseEvent | undefined>();
  const [openOptionModal, setOpenOptionModal] = React.useState(false);
  const handleOpenOptionModal = () => setOpenOptionModal(true);
  const handleCloseOptionModal = () => setOpenOptionModal(false);
  const handleCloseModal = () => setOpenModal(false);
  const [
    getPatients
  ] = useGetPatientsMutation();
  const [getData] = useGetDataByPatientMutation();
  const [
    putPatient,
    { isLoading: isLoadingPutPatient },
  ] = usePutPatientMutation();
  const [
    deletePatient,
    { isLoading: isLoadingDeletePatient },
  ] = useDeletePatientMutation();
  const [
    resetPassword,
    { isLoading: isLoadingResetPassword },
  ] = useResetPasswordMutation();
  const [
    getReport,
  ] = useGetReportMutation();
  const [
    transferToTouva,
    { isLoading: isLoadingTransfer },
  ] = usePostTransferToTouvaMutation();

  const [ getDocument ] = useGetDocumentMutation();

  const [
    getAdverseEvent,
    { isLoading: isLoadingGetAdverseEvent },
  ] = useGetAdverseEventMutation();

  function formatDateDisplayToDatabase(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    console.log(patient)
  }, [patient])

  useEffect(() => {
    const Start = async () => {
      try {
        setIsLoading(true);       
        const [patients, data, localAdverseEvent] = await Promise.all([
          getPatients().unwrap(),
          getData(loaderData.patientId as string).unwrap(),
          getAdverseEvent(loaderData.patientId as string).unwrap(),
        ]);
  
        const selectedPatient = patients.find(patient => patient.id === loaderData.patientId as string);
  
        if (selectedPatient) {
          setFormData({...selectedPatient, birthdate: formatDateDatabaseToDisplay(selectedPatient.birthdate) });
          dispatch(setPatient(selectedPatient));
        }
        if (localAdverseEvent) {
          const localAdverseEventCopy = [...localAdverseEvent];
          dispatch(setAdverseEvent(localAdverseEventCopy.sort((a, b) => b.timestamp - a.timestamp)));
        }
  
        if (data) {
          dispatch(setData(data));
        }
      } catch (error) {
        console.log(error)
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
  
    Start();
  }, [dispatch, getAdverseEvent, getData, getPatients, loaderData.patientId, setIsError, setIsLoading]);

  const handleIdentificationPDF = async () => {
    const result = await resetPassword({id: patient.id}).unwrap();
    const document = await getDocument({filename: 'document.pdf'}).unwrap();
    const existingPdfBytes = await fetch(document.url).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const form = pdfDoc.getForm();
  
    const textField1 = form.getTextField('username');
    textField1.setText(patient.id);
    textField1.enableReadOnly();

    const textField2 = form.getTextField('password');
    textField2.setText(result.password);
    textField2.enableReadOnly();
  
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });

    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl, '_blank');
  };

  const handleFieldChange = (fieldName: keyof Patient) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: event.target.value,
    }));
  };

  const handleDropOutPatient = async () => {
    try {
      if (patient.patient_status !== PatientStatus.DropOut){
        const changedPatient = Object.assign({}, patient, {'patient_status': PatientStatus.DropOut});
        await putPatient({...changedPatient, birthdate: formatDateDisplayToDatabase(changedPatient.birthdate)}).unwrap()
        if (admin.adminId) {
          const patients = await getPatients().unwrap();
          dispatch(
            setPatients(patients)
          );
        }
      }
      navigate('/root/dashboard');
    } catch (error) {
      console.error(error);
    }
  };

  function formatDateDatabaseToDisplay(dateStr:string) {
    const parts = dateStr.split('-');
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }

  const handleDeletePatient = async () => {
    try {
      if (patient.patient_status !== PatientStatus.Deleted){
        const changedPatient = Object.assign({}, patient, {'patient_status': PatientStatus.Deleted});
        await putPatient({...changedPatient, birthdate: formatDateDisplayToDatabase(changedPatient.birthdate)}).unwrap()
        if (admin.adminId) {
          const patients = await getPatients().unwrap();
          dispatch(
            setPatients(patients)
          );
        }
      }
      navigate('/root/dashboard');
    } catch (error) {
      console.error(error);
    }
  };

  const handleResetPatientPassword = async () => {
    try {
      setIsLoadingResetPassword2(true)
      const response = await resetPassword({id: patient.id}).unwrap();
      setPassword(response.password)
      await handleIdentificationPDF();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingResetPassword2(false)
    }
  };

  const handleDeleteDefinitivelyPatient = async () => {
    try {
      await deletePatient({id: patient.id}).unwrap()
      if (admin.adminId) {
        const patients = await getPatients().unwrap();
        dispatch(
          setPatients(patients)
        );
        navigate('/root/dashboard');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleTransferToTouvaPatient = async () => {
    try {
      if (patient.transferred === false) {
        await transferToTouva({id: patient.id}).unwrap();
        setFormData((prevFormData) => ({
          ...prevFormData,
          'transferred': true,
        }));
        dispatch(setPatient({...patient, transferred: true }))
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    await putPatient({...formData, birthdate: formatDateDisplayToDatabase(formData.birthdate)}).unwrap();
    dispatch(setPatient(formData));
  };
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        backgroundColor: Colors.mainBackgroundColor,
        height: '100%'
      }}>
      <HandleLoadingError isError={false} isLoading={isLoading}>
        <Box sx={{width: '100%'}}>
          <Box sx={{backgroundColor: 'white', borderRadius: 10, margin: 5, padding: 5, boxShadow: 1}}>
            {navigationValue === 1 ? (<Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="ID"
                    value={formData.id}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="group-label">Status</InputLabel>
                    <Select
                      labelId="group-label"
                      id="patient_status"
                      name="patient_status"
                      label="Groupe"
                      value={formData.patient_status}
                      disabled
                      fullWidth
                    >
                      <MenuItem value={PatientStatus.Selected}>Selectionné</MenuItem>
                      <MenuItem value={PatientStatus.Included}>Inclus</MenuItem>
                      <MenuItem value={PatientStatus.Finished}>Fini</MenuItem>
                      <MenuItem value={PatientStatus.Deleted}>Supprimé</MenuItem>
                      <MenuItem value={PatientStatus.DropOut}>Perdu de vue</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Phase"
                    value={formData.phase}
                    onChange={handleFieldChange('phase')}
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="site-label">Site</InputLabel>
                    <Select
                      labelId="site-label"
                      id="site"
                      name="site"
                      label="Site"
                      value={formData.site}
                      disabled={admin.admin_role !== AdminRole.Admin}
                      onChange={handleFieldChange('site')}
                    >
                      {
                        sites.filter(site => admin.admin_role !== AdminRole.Admin ? admin.site.includes(site.id) : true ).map(site => (
                          <MenuItem key={site.id} value={site.id}>{site.id}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="group-label">Groupe * </InputLabel>
                    <Select
                      labelId="group-label"
                      id="patientGroup"
                      name="patientGroup"
                      label="Groupe"
                      value={formData.patient_group}
                      onChange={handleFieldChange('patient_group')}
                      fullWidth
                    >
                      <MenuItem value="clinically at risk">Cliniquement à risque</MenuItem>
                      <MenuItem value="no clinically at risk">Pas cliniquement à risque</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    disabled={true}
                    label="Rang"
                    value={formData.rank}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    disabled={true}
                    label="Créateur"
                    value={formData.admin_id}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Box display="flex" alignItems="center">
                      <TextField
                        type="number"
                        label="IMC déclaré"
                        name="bmi"
                        id="bmi"
                        value={formData.bmi}
                        onChange={handleFieldChange('bmi')}
                        fullWidth
                      />
                      <Typography sx={{marginLeft: 2}}>kg/m²</Typography>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <TextField
                      type="date"
                      id="expiration_timestamp"
                      name="expiration_timestamp"
                      label="Expiration"
                      value={convertirTimestamp(formData.expiration_timestamp)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <TextField
                      type="date"
                      id="birthdate"
                      name="birthdate"
                      label="Date de naissance"
                      value={formData.birthdate}
                      onChange={handleFieldChange('birthdate')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="sex-label">Sexe</InputLabel>
                    <Select
                      labelId="sex-label"
                      id="sex"
                      name="sex"
                      label="Sexe"
                      value={formData.sex}
                      onChange={handleFieldChange('sex')}
                      fullWidth
                    >
                      <MenuItem value="man">Homme</MenuItem>
                      <MenuItem value="women">Femme</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Typography color={'InfoText'} sx={{paddingY: 1}}>* Les groupes sont définis comme suit : le groupe cliniquement à risque comprend ceux avec un IMC entre 25 et 35 kg/m², tandis que le groupe non-cliniquement à risque inclut ceux avec un IMC entre 18,5 et 25 kg/m² sans critères de syndrome métabolique.</Typography>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      id="note"
                      name="note"
                      label="Note"
                      multiline
                      onChange={handleFieldChange('note')}
                      value={formData.note}
                      rows={4}
                      variant='outlined'
                      inputProps={{maxLength: 300}}
                    />
                  </FormControl>
                  <FormHelperText>Veuillez ne pas ajouter de données identifiantes afin de garantir la confidentialité et la sécurité des informations.</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={handleSubmit} disabled={isLoadingPutPatient || isLoadingResetPassword || isLoadingDeletePatient} variant='contained'>Modifier</Button>
                </Grid>
              </Grid>
            </Box>
            ) : navigationValue === 3 ? (
              <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                {data.find(item => item.question_id === '4f56a9ed-7437-4d7d-b91e-7b0144121736')?.answer === 'true' ? (
                  <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography>Vous pouvez télécharger le rapport ci-dessous: </Typography>
                    <Button onClick={async () => {
                      const data = await getReport({id: formData.id}).unwrap();
                      window.open(data.url)
                    }} sx={{marginY: 3}} variant="contained" endIcon={<DownloadIcon />}>
                      Telecharger
                    </Button>
                  </Box>
                ) : (
                  <Typography sx={{marginY: 3}}>Pas de rapport</Typography>
                )}
              </Box>
            ) : navigationValue === 2 ? (<Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <Typography variant='h3'>Options</Typography>
              <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <FormControl fullWidth>
                  <TextField
                    id="report_problem"
                    name="report_problem"
                    label="Information d'incident"
                    multiline
                    onChange={handleFieldChange('report_problem')}
                    value={formData.report_problem}
                    rows={4}
                    variant='outlined'
                    inputProps={{maxLength: 300}}
                  />
                </FormControl>
                <Button sx={{alignSelf: 'center', margin: 3}} onClick={handleSubmit} disabled={isLoadingPutPatient || isLoadingResetPassword || isLoadingDeletePatient} variant='contained'>Envoyer des informations d&apos;incident</Button>
                <Divider sx={{width: '80%', alignSelf: 'center', backgroundColor: 'black', height: 1, marginBottom: 2}}/>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  {!isLoadingResetPassword2 ? <Button variant="contained" sx={{margin: 1}} onClick={() => {
                    setOptionModalFunction(() => () => handleResetPatientPassword());
                    handleOpenOptionModal();
                  }}>Changer de mot de passe</Button> : <CircularProgress sx={{marginX: 5}} />}
                  <Button disabled={patient.patient_status === PatientStatus.Deleted} variant="contained" color="error" sx={{margin: 1}} onClick={() => {
                    setOptionModalFunction(() => () => handleDeletePatient());
                    handleOpenOptionModal();
                  }}>Exclure</Button>
                  <Button disabled={patient.patient_status === PatientStatus.DropOut} variant="contained" color="warning" sx={{margin: 1}} onClick={() => {
                    setOptionModalFunction(() => () => handleDropOutPatient());
                    handleOpenOptionModal();
                  }}>Perdu de vue</Button>
                  { admin.admin_role === AdminRole.Admin && (<Button variant="contained" color="error" sx={{margin: 1}} onClick={() => {
                    setOptionModalFunction(() => () => handleDeleteDefinitivelyPatient());
                    handleOpenOptionModal();
                  }}>Supprimer</Button>)}
                </Box>
                <Divider sx={{width: '80%', alignSelf: 'center', backgroundColor: 'black', height: 1, marginY: 2}}/>
                { admin.admin_role === AdminRole.Admin && patient.transferred && (
                  <Typography style={{textAlign: 'center', color: 'green'}}>Déjà transféré</Typography>)}
                { admin.admin_role === AdminRole.Admin && ( isLoadingTransfer ? (
                  <CircularProgress color='info' size={30} sx={{margin: 1, alignSelf: 'center'}}/>
                ) : (
                  <>
                    <Button disabled={patient.transferred || patient.report !== true} variant="contained" color="info" sx={{margin: 1}} onClick={() => {
                      setOptionModalFunction(() => () => handleTransferToTouvaPatient());
                      handleOpenOptionModal();
                    }}>Transferer</Button>
                    {patient.report !== true && <Typography style={{textAlign: 'center', color: 'red'}}>Le rapport n&apos;a pas encore été généré</Typography>}
                  </>
                ))}
              </Box>
            </Box>) : (
              <Box>
                <Button onClick={() => {setOpenAdverseEventModal(true); setAdverseEventPutForModal(undefined);}}>Créer un évènement</Button>
                <Card variant="outlined" sx={{ height: 300 }}>
                  <Box sx={{ maxHeight: '100%', overflow: 'auto', p: 3 }}>
                    {isLoadingGetAdverseEvent ? 
                      <Box sx={{alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                        <CircularProgress />
                      </Box> 
                      : 
                      adverseEvent.map(value => (
                        <AdverseEventItem key={value.id} event={value} onEdit={(event) => {
                          setAdverseEventPutForModal(event);
                          setOpenAdverseEventModal(true);
                        }} />
                      ))}
                  </Box>
                </Card>
              </Box>
            )}
            <BottomNavigation
              showLabels
              value={navigationValue}
              onChange={(event, newValue) => {
                setNavigationValue(newValue);
              }}
            >
              <BottomNavigationAction value={1} label="profile" icon={<AccessibilityIcon />} />
              <BottomNavigationAction value={2} label="modifier status" icon={<TuneIcon />} />
              <BottomNavigationAction value={3} label="rapport" icon={<AnalyticsIcon />} />
              <BottomNavigationAction value={4} label="Evenement indésirable" icon={<ErrorIcon />} />
            </BottomNavigation>
          </Box>
          <Box sx={{ width: '100%'}}>{ patient.id !== '' && (<ProgressSteps patient={patient} />)}</Box>
        </Box>
        <Modal
          open={openOptionModal}
          onClose={handleCloseOptionModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Box sx={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: 2}}>
              <WarningIcon color='error' sx={{ fontSize: 40 }} />
              <Typography color='error'>Attention, cette action a des effets irréversibles</Typography>
              <Typography id="modal-modal-title" sx={{textAlign: 'center'}} variant="h6" component="h2">
              Êtes-vous sûr ?
              </Typography>
              <Box>
                <Button onClick={() => {
                  optionModalFunction && optionModalFunction();
                  handleCloseOptionModal();
                }} color='info'>oui</Button>
                <Button onClick={() => {
                  handleCloseOptionModal();
                }} color='error'>non</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Box sx={{flex: 1, display: 'flex', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', borderTopLeftRadius: 20, borderTopRightRadius: 20}}>
              <CheckCircle style={{fontSize: 130, color: 'white', margin: 30}} />
            </Box>
            <Box sx={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: 2}}>
              <Typography id="modal-modal-title" sx={{textAlign: 'center'}} variant="h6" component="h2">
      Un nouveau mot de passe a été créé avec succès
              </Typography>
              <Typography id="modal-modal-description" sx={{ marginY: 1 }}>
      Veuillez copier le code et le sauvegarder
              </Typography>
              <Box>
                <TextField disabled value={patient.id} id="identifiant" label="identifiant"  variant="outlined" />
                <CopyButton textToCopy={patient.id} />
              </Box>
              <Typography id="modal-modal-description" sx={{ marginY: 1 }}>
      Veuillez copier le code et le sauvegarder
              </Typography>
              <Box>
                <TextField disabled value={password} id="password" label="mot de passe"  variant="outlined" />
                <CopyButton textToCopy={password} />
              </Box>
              <Button onClick={() => {
                handleCloseModal();
              }} color='error'>Fermer</Button>
            </Box>
          </Box>
        </Modal>
        <AdverseEventModal open={openAdverseEventModal || adverseEventPutForModal !== undefined} handleClose={() => {setOpenAdverseEventModal(false); setAdverseEventPutForModal(undefined)}} put={adverseEventPutForModal !== undefined} adverseEvent={adverseEventPutForModal}/>
      </HandleLoadingError>
    </Box>
  );
}
