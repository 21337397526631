import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Question } from '../../types/types';
import { QuestionState } from '../type';

const questionSlice = createSlice({
  name: 'question',
  initialState: {
    questions: [],
  } as QuestionState,
  reducers: {
    setQuestion(state, action: PayloadAction<Question[]>) {
      state.questions = action.payload;
    },
  },
});

export const { setQuestion } = questionSlice.actions;

export const questionReducer = questionSlice.reducer;
