/* eslint-disable @typescript-eslint/ban-types */
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../../utils/api';
import { Admin, PostAdmin, PutAdmin, PutAdminRule } from '../../types/types';

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAdmins: builder.mutation<
      Admin[],
      void
    >({
      query: () =>
        `/admin`,
    }),
    getAdmin: builder.mutation<
      Admin,
      {
        id: string;
      }
    >({
      query: ({ id }) => ({
        url: `/admin/${id}`
      }),
    }),
    postAdmin: builder.mutation<
      {message: string},
      PostAdmin
    >({
      query: ({ site, first_name, last_name, admin_role, email}) => ({
        url: `/admin`,
        method: 'POST',
        body: JSON.stringify({
          site,
          first_name,
          last_name,
          admin_role,
          email
        }),
      }),
    }),
    putAdmin: builder.mutation<
      string,
      PutAdmin
    >({
      query: ({ id, site, first_name, last_name, admin_role, email}) => ({
        url: `/admin/${id}`,
        method: 'PUT',
        body: JSON.stringify({
          site,
          first_name,
          last_name,
          admin_role,
          email
        }),
      }),
    }),
    deleteAdmin: builder.mutation<
      string,
      {id: string}
    >({
      query: ({ id}) => ({
        url: `/admin/${id}`,
        method: 'DELETE',
      }),
    }),
    getDocument: builder.mutation<
      {url: string},
      {
        filename: string;
      }
    >({
      query: ({ filename }) => ({
        url: `/admin/documentation/${filename}`
      }),
    }),
    getRule: builder.mutation<
      PutAdminRule[],
      void
    >({
      query: () => ({
        url: `/admin/rule`
      }),
    }),
  }),
});

export const {
  useGetAdminsMutation,
  useGetAdminMutation,
  usePostAdminMutation,
  usePutAdminMutation,
  useDeleteAdminMutation,
  useGetDocumentMutation,
  useGetRuleMutation,
} = adminApi;
