import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Site } from '../../types/types';
import { SiteState } from '../type';

const siteSlice = createSlice({
  name: 'site',
  initialState: {
    sites: [],
  } as SiteState,
  reducers: {
    setSite(state, action: PayloadAction<Site[]>) {
      state.sites = action.payload;
    },
  },
});

export const { setSite } = siteSlice.actions;

export const siteReducer = siteSlice.reducer;
