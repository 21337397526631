/* eslint-disable @typescript-eslint/ban-types */
import { createApi } from '@reduxjs/toolkit/query/react';
import { Question } from '../../types/types';
import { baseQueryWithReauth } from '../../utils/api';

export const questionApi = createApi({
  reducerPath: 'questionApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getQuestionsByPhase: builder.mutation<
      Question[],
      void
    >({
      query: () =>
        `/admin/question`,
    }),
  }),
});

export const {
  useGetQuestionsByPhaseMutation,
} = questionApi;
