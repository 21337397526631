import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  TextField,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useLoaderData } from 'react-router-dom';
import { PatientPageLoaderData } from '../../types/types';
import { usePostQueryMutation } from '../../store/api';

interface Props {
  open: boolean;
  handleClose: () => void;
  question_id: string;
  question_answer: string;
  question: string;
}

const QueryModal: React.FC<Props> = ({ open, handleClose, question_id, question, question_answer }) => {
  const loaderData = useLoaderData() as PatientPageLoaderData;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    message: '',
  });

  const [
    postQuery,
  ] = usePostQueryMutation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await postQuery({...formValues, patient_id: loaderData.patientId as string, question_id: question_id, question: question, question_answer: question_answer }).unwrap();
      handleClose()
    } catch (error) {
      console.error('Erreur lors de la soumission des données:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormComplete = Object.entries(formValues).every(([key, value]) => value !== '' && value !== null);

  return (
    <Dialog maxWidth={'sm'} fullWidth open={open} onClose={handleClose} >
      <DialogTitle sx={{ backgroundColor: '#1976d2', color: 'white' }}>
        Rapporter un query
      </DialogTitle>
      <form style={{padding: 20}} onSubmit={handleSubmit}>
        <TextField
          name="message"
          label="Commentaire"
          variant="outlined"
          fullWidth
          multiline
          margin="normal"
          value={formValues.message}
          onChange={handleInputChange}
          rows={4}
          inputProps={{maxLength: 300}}
        />
        <Typography variant='body1'>300 caractères maximum</Typography>
        <Button sx={{marginTop: 2}} variant="contained" color="primary" type="submit" disabled={!isFormComplete || isSubmitting}>
          {isSubmitting ? <CircularProgress size={24} /> : 'Soumettre'}
        </Button>
      </form>
    </Dialog>
  );
};

export default QueryModal;
