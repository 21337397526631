import React from 'react';
import Error from '../error/error';
import { Box, CircularProgress } from '@mui/material';

type HandleLoadingErrorProps = {
  isError: boolean;
  isLoading: boolean;
  children: React.ReactNode;
};

const HandleLoadingError: React.FC<HandleLoadingErrorProps> = ({
  isError,
  isLoading,
  children,
}) => {
  if (isError) {
    return <Error />;
  } else if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh',
        }}>
        <CircularProgress />
      </Box>
    );
  } else {
    return <>{children}</>;
  }
};

export default HandleLoadingError;
