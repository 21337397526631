/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, CircularProgress, FormControl, Grid, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useGetAnalyseDataByPatientMutation, usePostAnalyseDataMutation } from '../store/api';

type FormData = {
  '2aef3a25-8029-4710-b592-79b6aa9fe2bb': string;
  'bab3c091-7061-4bf2-b791-84e117c5060d': string;
  'e2cfcc73-9400-4cbc-a8ad-a8e154167f40': string;
};

export default function AnalyseKitPage() {
  const [kitId, setKitId] = useState<string>('');
  const [patientId, setPatientId] = useState<string>('');
  const [searchError, setSearchError] = useState<string>('');
  const [isPatient, setIsPatient] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    '2aef3a25-8029-4710-b592-79b6aa9fe2bb': '',
    'bab3c091-7061-4bf2-b791-84e117c5060d': '',
    'e2cfcc73-9400-4cbc-a8ad-a8e154167f40': '',
  });
  const [postAnalyseData] = usePostAnalyseDataMutation();
  const [
    getAnalyseData,
    { isLoading: isLoadingGetAnalyseData },
  ] = useGetAnalyseDataByPatientMutation();

  const handleChange = async (event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name as string]: value,
    }));
    await postAnalyseData({'id': patientId, data: {answer: value, question_id: name}}).unwrap();
  };

  const handleChangeKit = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    setKitId(value);
  }

  const searchKit = async () => {
    try {
      setSearchError('');
      const response = await getAnalyseData(kitId).unwrap();
      if ('message' in response) {
        setIsPatient(false);
        setSearchError('Kit pas trouvé');
        return
      }
      if(response) {
        setIsPatient(true);
        setPatientId(response.patient_id)
      }
      setFormData({
        '2aef3a25-8029-4710-b592-79b6aa9fe2bb': response.data.find(x => x.question_id === '2aef3a25-8029-4710-b592-79b6aa9fe2bb' )?.answer ?? '',
        'bab3c091-7061-4bf2-b791-84e117c5060d': response.data.find(x => x.question_id === 'bab3c091-7061-4bf2-b791-84e117c5060d' )?.answer ?? '',
        'e2cfcc73-9400-4cbc-a8ad-a8e154167f40': response.data.find(x => x.question_id === 'e2cfcc73-9400-4cbc-a8ad-a8e154167f40' )?.answer ?? '',
      })
    } catch (err : Error | any) {
      setSearchError(err.message);
    }
    
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: 3,
      }}>
      <h1>Admins</h1>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          backgroundColor: 'white',
          boxShadow: 1,
          borderRadius: 5,
          padding: 3,
          flexDirection: 'column'
        }}
      >
        <Box sx={{
          display: 'flex',
          backgroundColor: 'white'}}>
          <Box>
            <TextField
              label={"Numéro de kit"}
              value={kitId}
              onChange={handleChangeKit}
            />
            <Typography sx={{color: 'red', marginY: 1}} variant='subtitle2'>{searchError}</Typography>
          </Box>
            
          <Box>
            <Button sx={{marginX: 3}} onClick={searchKit} variant="contained">Rechercher</Button>
          </Box>
        </Box>

        {
          isLoadingGetAnalyseData ? (
            <Box sx={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}><CircularProgress /></Box>
          ) : isPatient && (
            <Box sx={{marginTop: 2}}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="date"
                      id="date1"
                      name="2aef3a25-8029-4710-b592-79b6aa9fe2bb"
                      label="Date prélèvement"
                      value={formData['2aef3a25-8029-4710-b592-79b6aa9fe2bb']}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="date"
                      id="date2"
                      name="bab3c091-7061-4bf2-b791-84e117c5060d"
                      label="Date kit envoyé"
                      value={formData['bab3c091-7061-4bf2-b791-84e117c5060d']}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="date"
                      id="date3"
                      name="e2cfcc73-9400-4cbc-a8ad-a8e154167f40"
                      label="Date de reception"
                      value={formData['e2cfcc73-9400-4cbc-a8ad-a8e154167f40']}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )
        }
      </Box>
    </Box>
  );
}
