import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useGetDocumentMutation, usePostDataMutation, usePostPatientMutation } from '../store/api';
import { adminSelector, questionSelector } from '../store/selectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AdminRole, SiteType } from '../types/types';
import { siteSelector } from '../store/selectors/siteSelector';
import { CheckCircle } from '@mui/icons-material';
import CopyButton from '../components/button/copy-button';

type FormPatient = {
    site: string;
    bmi: number;
    birthdate: string;
    sex: string;
    note: string;
  };

type FormData = {
  [id: string]: boolean | number | string;
};
  
const initialFormPatient: FormPatient = {
  site: '',
  bmi: 0,
  birthdate: '',
  sex: '',
  note: '',
};

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column'
};

export default function CreatePatientPage() {
  const [formPatient, setFormPatient] = React.useState<FormPatient>(initialFormPatient);
  const [formData, setFormData] = useState<FormData>({});
  const [bmiError, setBmiError] = useState<string>('');
  const [noteError, setNoteError] = useState<string>('');
  const [birthdateError, setBirthdateError] = useState<string>('');
  const [createPatientId, setCreatePatientId] = useState<string>('');
  const [siteType, setSiteType] = useState<SiteType>();
  const navigate = useNavigate();
  const questions = useSelector(questionSelector).questions;
  const sites = useSelector(siteSelector).sites;
  const admin = useSelector(adminSelector)
  const [
    postPatient,
    { isLoading: isLoadingPatient },
  ] = usePostPatientMutation();
  const [
    postData,
    { isLoading: isLoadingData },
  ] = usePostDataMutation();
  const [
    getDocument,
    { isLoading: isLoadingGetDocument },
  ] = useGetDocumentMutation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePrintPDF = async (url: string) => {
    const result = await getDocument({filename: url}).unwrap();
    window.open(result.url, '_blank');
  };

  function isAgeBetween18And70(birthDateString:string) {
    const birthDate = new Date(birthDateString);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age >= 18 && age <= 70;
  }

  useEffect(() => {
    setFormData(questions.filter(question => question.step === '1').reduce((acc, { id }) => ({ ...acc, [id]: false }), {}))
  }, [questions])

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const handleChangePatient = (event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormPatient((prevData) => ({
      ...prevData,
      [name as string]: value,
    }));
  };

  const handleChangeData = (id: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [id]: !formData[id],
    }));
  };

  async function sendFormDataInBatches(formData : FormData, patientId : string) {
    const batchSize = 10;
    const entries = Object.entries(formData);
    const totalEntries = entries.length;
    let currentIndex = 0;
  
    while (currentIndex < totalEntries) {
      const currentBatch = entries.slice(currentIndex, currentIndex + batchSize);
      const promises = currentBatch.map( async ([id, value]) => {
        return await postData({
          id: patientId,
          data: {
            question_id: id,
            answer: String(value),
          }
        });
      });
      await Promise.all(promises);
      currentIndex += batchSize;
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setBmiError('');
    setNoteError('');
    setBirthdateError('');
    if(Object.entries(formPatient).filter(([key]) => key !== 'note').find(([, value]) => value === '' || value === undefined) !== undefined){
      setNoteError('Vous devez compléter tout les champs')
      return
    }
    if(formPatient.bmi < 18 || formPatient.bmi >= 35) {
      setBmiError('La valeur doit être comprise entre 18 et 35 exclus');
      return 0
    }
    if(!isAgeBetween18And70(formPatient.birthdate)){
      setBirthdateError('Le patient doit être agé entre 18 et 69 ans')
      return 0;
    }
    if (formPatient.site) {
      const responsePatient = await postPatient({
        patient_status: 'SELECTED',
        phase: "1",
        site: formPatient.site,
        step: "1",
        sex: formPatient.sex,
        birthdate: formatDate(formPatient.birthdate),
        bmi: formPatient.bmi,
        note: formPatient.note,
      }).unwrap();
      setCreatePatientId(responsePatient.id);
      setSiteType(sites.find(site => site.id === formPatient.site)?.type ?? SiteType.heal)
      handleOpen();
      sendFormDataInBatches(formData, responsePatient.id)
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
      }}>
      <h1>Créer un patient</h1>
      <Divider sx={{marginY: 1, height: 1, backgroundColor: 'black'}} />
      <form style={{display: 'flex', flexDirection: 'column'}}>
        <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
          {questions.filter(question => question.step === '1').sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort)).map((question) => (
            <Card onClick={() => handleChangeData(question.id)} key={question.id} sx={{padding: '16px', backgroundColor: formData[question.id] ? 'green' : 'white', borderRadius: 5, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', cursor: 'pointer' }}>
              {question.type === 'boolean' && (
                <Typography>{question.label}</Typography>
              )}
              {question.description && question.description != "nan" && question.description.split('/n').map((text,index) => (
                <Typography key={index}>- {text}</Typography>
              ))}
            </Card>
          ))}
        </Box>
      </form>
      <h4 style={{marginTop: 20, marginBottom: 20}}>Profil patient</h4>
      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="site-label">Centre</InputLabel>
              <Select
                labelId="site-label"
                id="site"
                name="site"
                label="Centre"
                value={formPatient.site}
                onChange={handleChangePatient}
                fullWidth
              >
                {
                  sites.filter(site => admin.admin_role !== AdminRole.Admin ? admin.site.includes(site.id) : true ).map(site => (
                    <MenuItem key={site.id} value={site.id}>{site.city}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Box display="flex" alignItems="center">
                <TextField
                  type="number"
                  label="IMC déclaré"
                  name="bmi"
                  id="bmi"
                  value={formPatient.bmi}
                  error={bmiError !== '' ? true : false}
                  onChange={handleChangePatient}
                  fullWidth
                />
                <Typography sx={{marginLeft: 2}}>kg/m²</Typography>
              </Box>
              {bmiError !== '' && <Typography sx={{color: 'red'}} variant='subtitle2'>{bmiError}</Typography>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                type="date"
                id="birthdate"
                name="birthdate"
                label="Date de naissance"
                value={formPatient.birthdate}
                error={birthdateError !== '' ? true : false}
                onChange={handleChangePatient}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </FormControl>
            {birthdateError !== '' && <Typography sx={{color: 'red'}} variant='subtitle2'>{birthdateError}</Typography>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="sex-label">Sexe</InputLabel>
              <Select
                labelId="sex-label"
                id="sex"
                name="sex"
                label="Sexe"
                value={formPatient.sex}
                onChange={handleChangePatient}
                fullWidth
              >
                <MenuItem value="man">Homme</MenuItem>
                <MenuItem value="women">Femme</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <TextField
                id="note"
                name="note"
                label="Note"
                multiline
                onChange={handleChangePatient}
                value={formPatient.note}
                rows={4}
                variant='outlined'
                inputProps={{maxLength: 300}}
              />
              <FormHelperText>Veuillez ne pas ajouter de données identifiantes afin de garantir la confidentialité et la sécurité des informations.</FormHelperText>
              {noteError !== '' && <Typography sx={{color: 'red'}} variant='subtitle2'>{noteError}</Typography>}
            </FormControl>
          </Grid>
        </Grid>
        {
          isLoadingPatient || isLoadingData ? (
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <CircularProgress size={50} />
            </Box>
          ) : (
            <Button disabled={isLoadingPatient || isLoadingData || Object.entries(formPatient).filter(([key]) => key !== 'note').find(([, value]) => value === '') !== undefined} sx={{margin: 4, width: '30%', alignSelf: 'center'}} type="submit" variant="contained" color="primary" fullWidth>
            Créer le patient
            </Button>
          )
        }
      </form>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          navigate('/root/patient');
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={{flex: 1, display: 'flex', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', borderTopLeftRadius: 20, borderTopRightRadius: 20}}>
            <CheckCircle style={{fontSize: 130, color: 'white', margin: 30}} />
          </Box>
          <Box sx={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: 2}}>
            <Typography id="modal-modal-title" sx={{textAlign: 'center'}} variant="h6" component="h2">
      Un nouveau patient a été créé avec succès
            </Typography>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Typography id="modal-modal-title" sx={{textAlign: 'center'}} variant="h5" component="h2">
                {createPatientId}
              </Typography>
              <CopyButton textToCopy={createPatientId} />
            </Box>
            {
              isLoadingGetDocument ? (
                <CircularProgress />
              ) : (
                <Button onClick={() => handlePrintPDF(siteType === SiteType.heal ? `2023-A02494-41_NIFC_soins_version_finale.pdf` : `2023-A02494-41_NIFC_recherche_version_finale.pdf`)}>Imprimer la note d&apos;information</Button>
              )}
            <Button onClick={() => {
              handleClose();
              navigate('/root/patient');
            }} color='error'>Fermer</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
