import { LoaderFunctionArgs, createBrowserRouter } from 'react-router-dom';
import React from 'react';
import Root from './root';
import ErrorPage from '../pages/error-page';
import SettingsPage from '../pages/settings-page';
import FirstSignIn from '../pages/first-sign-in-page';
import ForgotPassword from '../pages/forgot-password';
import SignInAdminPage from '../pages/sign-in-page-admin';
import DashboardPage from '../pages/dashboard-page';
import PatientsPage from '../pages/patients-page';
import PatientPage from '../pages/patient-page';
import CreatePatientPage from '../pages/create-patient-page';
import AdminsPage from '../pages/admins-page';
import AnalyseKitPage from '../pages/analyse-kit-page';
import DocumentPage from '../pages/document-page';
import UnauthenticatedRoute from './unauthenticated-route';
import AuthenticatedRoute from './authenticated-route';
import QueryPage from '../pages/query-page';
import DataPage from '../pages/data-page';

export async function loader({ params }: LoaderFunctionArgs) {
  const patientId = params.patientId;
  return { patientId };
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <UnauthenticatedRoute>
        <SignInAdminPage />
      </UnauthenticatedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: (
      <UnauthenticatedRoute>
        <SignInAdminPage />
      </UnauthenticatedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/forgotPassword',
    element: (
      <UnauthenticatedRoute>
        <ForgotPassword />
      </UnauthenticatedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/firstLogin',
    element: (
      <UnauthenticatedRoute>
        <FirstSignIn />
      </UnauthenticatedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/root',
    element: (
      <AuthenticatedRoute>
        <Root />
      </AuthenticatedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'dashboard',
        element: <DashboardPage />,
      },
      {
        path: 'patient',
        element: <PatientsPage />,
      },
      {
        path: 'create-patient',
        element: <CreatePatientPage />,
      },
      {
        path: 'query',
        element: <QueryPage />,
      },
      {
        path: 'analyse-kit',
        element: <AnalyseKitPage />,
      },
      {
        path: 'data',
        element: <DataPage />,
      },
      {
        path: 'patient/:patientId',
        element: <PatientPage />,
        loader: loader,
      },
      {
        path: 'admin',
        element: <AdminsPage />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
      {
        path: 'document',
        element: <DocumentPage />,
      },
    ],
  },
]);
