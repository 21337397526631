import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Box,
  Avatar,
  Typography,
} from '@mui/material';
import { isAdminSelector } from '../store/selectors';
import { useSelector } from 'react-redux';
import { PutAdmin } from '../types/types';
import { usePutAdminMutation } from '../store/api';
import { setAdmin } from '../store/slices';
import { useDispatch } from 'react-redux';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const SettingsPage: React.FC = () => {
  const dispatch = useDispatch();
  const admin = useSelector(isAdminSelector);
  const [putAdmin, { isLoading, isError }] = usePutAdminMutation();
  const [formData, setFormData] = useState<PutAdmin>({
    id: admin.adminId,
    site: admin.site,
    admin_role: admin.admin_role,
    first_name: admin.first_name,
    last_name: admin.last_name,
    email: admin.email,
  });

  useEffect(() => {
    setFormData({
      id: admin.adminId,
      site: admin.site,
      admin_role: admin.admin_role,
      first_name: admin.first_name,
      last_name: admin.last_name,
      email: admin.email,
    });
  }, [admin]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      await putAdmin(formData);
      dispatch(
        setAdmin({
          adminId: admin.adminId,
          site: admin.site,
          admin_role: admin.admin_role,
          first_name: admin.first_name,
          last_name: admin.last_name,
          email: admin.email,
          admins: [],
          adminRules: admin.adminRules,
          query: admin.query,
        })
      );
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Grid container justifyContent="center" sx={{ p: 3 }}>
      <Grid item xs={12} md={6}>
        <Box sx={{ display: 'flex', p: 5, alignItems: 'center' }}>
          <Avatar
            {...stringAvatar(admin.first_name + ' ' + admin.last_name)}
            sx={{ width: 100, height: 100 }}
          />
          <Typography sx={{ marginLeft: 3 }} variant="h5">
            {admin.first_name} {admin.last_name}
          </Typography>
        </Box>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            required
            id="first_name"
            label="Prénom"
            name="first_name"
            autoComplete="first_name"
            value={formData.first_name}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            required
            id="last_name"
            label="Nom de famille"
            name="last_name"
            autoComplete="last_name"
            value={formData.last_name}
            onChange={handleChange}
          />
          <Button
            disabled={isLoading}
            type="submit"
            variant="contained"
            sx={{ mt: 3 }}>
              Modifier mon profile
          </Button>
          {
            isError && <Typography variant='subtitle1' color={'red'}>Une erreur est survenue</Typography>
          }
        </Box>
      </Grid>
    </Grid>
  );
};

export default SettingsPage;
