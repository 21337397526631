import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { AppContext, AppContextType } from './components/authentication/account';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Provider } from 'react-redux';
import { store } from './store/store';
import './i18n';
import { Amplify, type ResourcesConfig } from 'aws-amplify';
import { defaultStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider, getCurrentUser } from 'aws-amplify/auth/cognito';
import { router } from './routes/router';
import ApiErrorHandler from './components/error/api-error-handler';

const authConfig: ResourcesConfig['Auth'] = {
  Cognito: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID_ADMIN || 'test',
    userPoolClientId: process.env.REACT_APP_CLIENT_ID_ADMIN || 'test'
  }
};

Amplify.configure({
  Auth: authConfig
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      setIsAuthenticating(true)
      await getCurrentUser();
      userHasAuthenticated(true);
    } catch (e) {
      userHasAuthenticated(false);
    }
    setIsAuthenticating(false);
  }
  
  return (
    <Provider store={store}>
      <AppContext.Provider
        value={{ isAuthenticating, isAuthenticated, isLoading, isError, userHasAuthenticated, setIsLoading, setIsError } as AppContextType}
      >
        <ApiErrorHandler />
        <RouterProvider router={router} />
      </AppContext.Provider>
    </Provider>
  );
}

export default App;
