import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { CognitoErrorHandler } from '../components/error/cognito-error-handler';
import { useTranslation } from 'react-i18next';
import { confirmSignIn } from 'aws-amplify/auth/cognito';
import { useAppContext } from '../components/authentication/account';

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://integrative-phenomics.com">
        Integrative phenomics
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function FirstSignIn() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { userHasAuthenticated } = useAppContext();

  const [passwordError, setPasswordError] = React.useState('');
  const [confirmPasswordError, setConfirmPasswordError] = React.useState('');

  const resetError = () => {
    setConfirmPasswordError('');
    setPasswordError('');
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    resetError();
    const data = new FormData(event.currentTarget);
    const password = data.get('password');
    const confirmPassword = data.get('confirmPassword');
    if (!password) {
      setPasswordError(t('error.missing.password') as string);
    }

    if (confirmPassword !== password) {
      setConfirmPasswordError(t('error.errorNotSamePassword') as string);
    }

    if (password && confirmPassword) {
      (async () => {
        try {
          await confirmSignIn({challengeResponse: password as string});
          userHasAuthenticated(true);
          navigate('/root/dashboard');
        } catch (err: Error | any) {
          setConfirmPasswordError(CognitoErrorHandler(err, t));
        }
      })();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('page.firstSign.title') as string}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label={t('label.newPassword') as string}
              name="password"
              type="password"
              autoComplete="password"
              autoFocus
              error={passwordError !== ''}
              helperText={passwordError}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label={t('label.confirmNewPassword') as string}
              type="password"
              id="confirmPassword"
              autoComplete="confirmPassword"
              error={confirmPasswordError !== ''}
              helperText={confirmPasswordError}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
              {t('label.changePassword') as string}
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
