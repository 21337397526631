import React, { useState, ChangeEvent } from 'react';
import { Button, Modal, Box, Typography, List, ListItem, ListItemText, IconButton, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';

const MAX_FILE_SIZE = 4.2 * 1024 * 1024; // Taille maximale de 4.2 Mo

const InputFile = styled('input')({
  display: 'none',
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DropZoneBox = styled(Box)({
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: '20px',
});

interface FileModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (files: File[]) => Promise<void>;
  maxFiles: number;
  isLoading: boolean;
  error: string | null;
}

const FileModal: React.FC<FileModalProps> = ({ open, onClose, onConfirm, maxFiles, isLoading, error }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [localError, setLocalError] = useState<string | null>(null);

  const onDrop = (acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.filter((file) => {
      if (file.size > MAX_FILE_SIZE) {
        setLocalError(`Le fichier ${file.name} est trop grand. La taille maximale du fichier est de 4,2 Mo.`);
        return false;
      }
      return true;
    });

    if (files.length + newFiles.length > maxFiles) {
      setLocalError(`Vous pouvez télécharger jusqu'à ${maxFiles} fichiers.`);
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setLocalError(null);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files ? Array.from(event.target.files) : [];
    const newFiles = selectedFiles.filter((file) => {
      if (file.size > MAX_FILE_SIZE) {
        setLocalError(`Le fichier ${file.name} est trop grand. La taille maximale du fichier est de 4,2 Mo.`);
        return false;
      }
      return true;
    });

    if (files.length + newFiles.length > maxFiles) {
      setLocalError(`Vous pouvez télécharger jusqu'à ${maxFiles} fichiers.`);
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setLocalError(null);
  };

  const handleRemoveFile = (fileToRemove: File) => {
    setFiles((prevFiles) => prevFiles.filter(file => file !== fileToRemove));
  };

  const handleConfirm = async () => {
    try {
      await onConfirm(files);
      setFiles([]);
      onClose();
    } catch (error) {
      console.error('Erreur lors du téléchargement des fichiers:', error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2">
          Télécharger des fichiers
        </Typography>
        {(error || localError) && <Typography color="error">{error || localError}</Typography>}
        <DropZoneBox {...getRootProps()}>
          <input {...getInputProps()} />
          <Typography>Glissez et déposez des fichiers ici, ou cliquez pour sélectionner des fichiers</Typography>
        </DropZoneBox>
        <label htmlFor="file-input">
          <InputFile
            accept="*"
            id="file-input"
            type="file"
            multiple
            onChange={handleFileChange}
            disabled={isLoading}
          />
          <Button variant="contained" component="span" disabled={isLoading}>
            Sélectionner des fichiers
          </Button>
        </label>
        <List>
          {files.map((file, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton disabled={isLoading} edge="end" aria-label="delete" onClick={() => handleRemoveFile(file)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={file.name} />
            </ListItem>
          ))}
        </List>
        {isLoading ? (
          <Box textAlign="center" mt={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Button disabled={isLoading} variant="contained" color="primary" onClick={handleConfirm} sx={{ mt: 2 }}>
            Confirmer et télécharger
          </Button>
        )}
      </Box>
    </Modal>
  );
};

export default FileModal;
