/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty-pattern */
import { createApi } from '@reduxjs/toolkit/query/react';
import { Patient, PostPatient, PostQuery, PostQueryAnswer, PutPatient, Query } from '../../types/types';
import { baseQueryWithReauth } from '../../utils/api';

export const patientApi = createApi({
  reducerPath: 'patientApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPatients: builder.mutation<
      Patient[],
      void
    >({
      query: () =>
        `/admin/patient`,
    }),
    postPatient: builder.mutation<
      {id: string; password: string;},
      PostPatient
    >({
      query: ({ patient_status, phase, step, site, bmi, birthdate, sex, note }) => ({
        url: `/admin/patient`,
        method: 'POST',
        body: JSON.stringify({
          "patient_status": patient_status,
          phase,
          site,
          step,
          bmi,
          birthdate,
          sex,
          note,
        }),
      }),
    }),
    putPatient: builder.mutation<
      string,
      PutPatient
    >({
      query: ({ id, patient_status, step, phase, site, patient_group, bmi, birthdate, sex, note, report, inclusion_timestamp, expiration_timestamp, anti_lipid, antihypertensives, anti_obesogens, menopause_status, diet, nursing, report_problem }) => ({
        url: `/admin/patient/${id}`,
        method: 'PUT',
        body: JSON.stringify({
          patient_status,
          phase,
          site,
          step,
          patient_group,
          bmi,
          birthdate,
          sex,
          note,
          report,
          inclusion_timestamp,
          expiration_timestamp,
          anti_lipid,
          antihypertensives,
          anti_obesogens,
          menopause_status,
          diet,
          nursing,
          report_problem
        }),
      }),
    }),
    deletePatient: builder.mutation<
      string,
      {id: string}
    >({
      query: ({ id }) => ({
        url: `/admin/patient/${id}`,
        method: 'DELETE',
      }),
    }),
    resetPassword: builder.mutation<
      { password: string },
      { id: string; }
    >({
      query: ({ id }) => ({
        url: `/admin/patient/${id}/resetpassword`,
      }),
    }),
    getReport: builder.mutation<
      { url: string },
      { id: string; }
    >({
      query: ({ id }) => ({
        url: `/admin/patient/${id}/report`,
      }),
    }),
    postTransferToTouva: builder.mutation<
      { message: string },
      { id: string; }
    >({
      query: ({ id }) => ({
        url: `/admin/patient/${id}/transfer`,
        method: 'POST',
      }),
    }),
    getQuery: builder.mutation<
      Query[],
      void
    >({
      query: () => ({
        url: `/admin/query`,
      }),
    }),
    postQuery: builder.mutation<
      { message: string },
      PostQuery
    >({
      query: ({ message, patient_id, question_id, question_answer, question }) => ({
        url: `/admin/query`,
        method: 'POST',
        body: JSON.stringify({
          message,
          patient_id,
          question_id,
          question,
          question_answer
        }),
      }),
    }),
    postQueryAnswer: builder.mutation<
      { message: string },
      PostQueryAnswer
    >({
      query: ({ query_id, answer }) => ({
        url: `/admin/query/${query_id}`,
        method: 'POST',
        body: JSON.stringify({
          answer
        }),
      }),
    }),
  }),
});

export const {
  useGetPatientsMutation,
  usePostPatientMutation,
  usePutPatientMutation,
  useDeletePatientMutation,
  useResetPasswordMutation,
  useGetReportMutation,
  usePostTransferToTouvaMutation,
  useGetQueryMutation,
  usePostQueryAnswerMutation,
  usePostQueryMutation
} = patientApi;
