import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material';
import React from 'react';
import Barcode from 'react-barcode';
import { useGetDocumentMutation, useResetPasswordMutation } from '../../store/api';
import { useSelector } from 'react-redux';
import { isPatientSelector } from '../../store/selectors';
import { PatientGroup } from '../../types/types';
import WarningIcon from '@mui/icons-material/Warning';
import { PDFDocument } from 'pdf-lib';

interface Props {
  id: string;
  visit: string;
}

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column'
};

const PrintPdf: React.FC<Props> = ({ id, visit }) => {
  const [
    resetPassword,
    { isLoading: isLoadingResetPassword },
  ] = useResetPasswordMutation();

  const [ getDocument ] = useGetDocumentMutation();

  const selectedPatient = useSelector(isPatientSelector).patient;

  const [openOptionModal, setOpenOptionModal] = React.useState(false);
  const handleOpenOptionModal = () => setOpenOptionModal(true);
  const handleCloseOptionModal = () => setOpenOptionModal(false);

  const handlePrintPDF = async () => {
    const result = await getDocument({filename: selectedPatient.patient_group === PatientGroup.ClinicallyAtRisk ? `2023-A02494-41_NIFC_soins_version_finale.pdf` : `2023-A02494-41_NIFC_recherche_version_finale.pdf`}).unwrap();
    window.open(result.url, '_blank');
  };

  const handleIdentificationPDF = async () => {
    const result = await resetPassword({id: id}).unwrap();
    const document = await getDocument({filename: 'document.pdf'}).unwrap();
    const existingPdfBytes = await fetch(document.url).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const form = pdfDoc.getForm();
  
    const textField1 = form.getTextField('username');
    textField1.setText(id);
    textField1.enableReadOnly();

    const textField2 = form.getTextField('password');
    textField2.setText(result.password);
    textField2.enableReadOnly();
  
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });

    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl, '_blank');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
      }}>
      <Typography color={'error'} sx={{width: '60%', textAlign: 'center', paddingBottom: 2}}><strong>Important :</strong> Le consentement doit être signé en deux exemplaires, et une copie doit être fournie au patient. Assurez-vous également que la note d’information est paraphée. Un exemplaire de chaque document doit être remis au patient.</Typography>
      <Button onClick={handlePrintPDF}>Imprimer consentement</Button>
      { isLoadingResetPassword ? (<CircularProgress sx={{padding: 1}} />) : (<Button onClick={handleOpenOptionModal}>Imprimer identifiants</Button>)}
      <Barcode value={id+ '-' + visit} />
      <Modal
        open={openOptionModal}
        onClose={handleCloseOptionModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: 2}}>
            <WarningIcon color='error' sx={{ fontSize: 40 }} />
            <Typography sx={{textAlign: 'center'}} color='error'>Attention, cette action a des effets irréversibles (régénérer un nouveau mot de passe)</Typography>
            <Typography id="modal-modal-title" sx={{textAlign: 'center'}} variant="h6" component="h2">
          Êtes-vous sûr ?
            </Typography>
            <Box>
              <Button onClick={() => {
                handleCloseOptionModal();
                handleIdentificationPDF();
              }} color='info'>oui</Button>
              <Button onClick={() => {
                handleCloseOptionModal();
              }} color='error'>non</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PrintPdf;