import { createApi } from '@reduxjs/toolkit/query/react';
import { Answer } from '../../types/types';
import { baseQueryWithReauth } from '../../utils/api';

export const answerApi = createApi({
  reducerPath: 'answerApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllAnswers: builder.mutation<
      Answer[],
      { surveyId: string; userId: string }
    >({
      query: ({ surveyId, userId }) =>
        `/patient/answers?userId=${userId}&surveyId=${surveyId}`,
    }),
    insertAnswer: builder.mutation<
      string,
      {
        id: string;
        label: string | number | true | string[];
        surveyId: string;
        userId: string;
      }
    >({
      query: ({ id, label, surveyId, userId }) => ({
        url: `/patient/answers/add`,
        method: 'POST',
        body: JSON.stringify({
          answer: label === null ? 'wait' : label,
          question_id: id,
          userId,
          surveyId,
        }),
      }),
    }),
    confirmAnswers: builder.mutation<
      string,
      { userId: string; surveyId: string; agree: boolean }
    >({
      query: ({ userId, surveyId, agree }) => ({
        url: `v1/imc/survey/answers/submit?userId=${userId}&surveyId=${surveyId}&agree=${agree}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetAllAnswersMutation,
  useInsertAnswerMutation,
  useConfirmAnswersMutation,
} = answerApi;
