import Box from '@mui/material/Box';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent, Chip, CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';
import { usePostQueryAnswerMutation } from '../../store/api';
import { Query } from '../../types/types';
import { setAdminQuery } from '../../store/slices';
import { isAdminSelector } from '../../store/selectors';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

interface QueryItemProps {
    item: Query;
  }

const convertTimestampToHour = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC'
  };
  return date.toLocaleString('fr-FR', options);
}
  
const QueryItem: React.FC<QueryItemProps> = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useSelector(isAdminSelector).query;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    answer: item.answer,
  });

  const [
    postQueryAnswer,
  ] = usePostQueryAnswerMutation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await postQueryAnswer({...formValues, query_id: item.id }).unwrap();
      const time = new Date().getTime();
      dispatch(setAdminQuery([...query.filter(value => item.id !== value.id), {...item, ...formValues, query_status: 'CLOSE', timestamp_answer: time}]))
    } catch (error) {
      console.error('Erreur lors de la soumission des données:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormComplete = Object.entries(formValues).every(([key, value]) => value !== '' && value !== null);

  return (
    <Grid key={item.id} item xs={12} sm={12} md={6} lg={6} xl={4} >
      <Card sx={{ minWidth: 275, marginBottom: 5 }}>
        <CardContent>
          <Box sx={{display:'flex', justifyContent: 'space-between'}}>
            <Typography>{convertTimestampToHour(item.timestamp)}</Typography>
            {item.query_status === 'OPEN' ? <Chip color='success' icon={<LockOpenIcon />} label="Ouvert" /> : <Chip color='error' icon={<LockIcon />} label="Fermé" />}
          </Box>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            {item.question} ({item.patient_id}) 
            {<IconButton aria-label="delete" onClick={() => navigate('/root/patient/' + item.patient_id)}>
              <ArrowForwardIcon color='info' />
            </IconButton>}
          </Typography>
          <Typography variant="h5" component="div">
            {item.question_answer}
          </Typography>
          <Typography sx={{ marginY: 1 }} color="text.secondary">
                    Commentaire :
          </Typography>
          <Typography variant="body2">
            {item.message}
          </Typography>
          <form style={{paddingTop: 10, width: '100%'}} onSubmit={handleSubmit}>
            <TextField
              name="answer"
              label="Reponse"
              variant="outlined"
              fullWidth
              multiline
              margin="normal"
              value={formValues.answer}
              onChange={handleInputChange}
              rows={4}
              inputProps={{maxLength: 300}}
              disabled={item.query_status !== 'OPEN'}
            />
            <Typography variant='body1'>300 caractères maximum</Typography>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button sx={{marginTop: 2}} variant="contained" color="primary" type="submit" disabled={!isFormComplete || isSubmitting || item.query_status !== 'OPEN'}>
                {isSubmitting ? <CircularProgress size={24} /> : 'Répondre'}
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default QueryItem;
