export enum SurveyStatus {
  ToStart = 'toStart',
  InProgress = 'inProgress',
  Finished = 'finished',
}

export interface FormData {
  [id: string]: boolean | number | string | Date;
};

export interface FormError {
  [question_id: string]: string;
};

export interface UserProfileRequest {
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  user_phone: string;
  user_address: string;
  user_postal_code: string;
  user_city: string;
  user_country: string;
  user_sex: string;
}

export interface UserProfileResponse {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address: string;
  postal_code: string;
  city: string;
  country: string;
  sex: string;
  doctor_id: string;
}

export interface ProgressInSurvey {
  progress: number;
}

export interface Answer {
  question_id: string;
  answer: string | string[] | number | boolean;
}

export interface SurveyQuestion {
  id: string;
  type: string;
  value: string;
  description: string;
  additional_description: string;
  help: string;
  help_title: string;
  image: string;
  tagIdList: string[];
  required: string;
  dependencies: {
    question_id?: string;
    answer?: string;
    effect?: string;
  }[];
  answers: {
    label?: string;
    icon?: string;
  }[];
  rules: {
    max?: string;
    min?: string;
  };
}

export interface SurveySection {
  id: string;
  title: string;
  description: string;
  additional_description: string;
  tags: string[];
  transition: string;
  transition_icon: string;
  questions: SurveyQuestion[];
}

export interface SurveyCategory {
  id: string;
  title: string;
  description: string;
  icon: string;
  sections: SurveySection[];
}

export interface SurveyInterface {
  id: string;
  title: string;
  date: string;
  description: string;
  progress: number;
  status: string;
  category: SurveyCategory[];
}

export interface SurveyPageLoaderData {
  surveyId: string;
}

// New type 

export interface Admin {
  site: string[];
  id: string;
  admin_role: AdminRole;
  first_name: string;
  last_name: string;
  email: string;
  timestamp_creation: number;
  query: Query[]
}

export interface PostAdmin {
  site: string[];
  admin_role: AdminRole;
  first_name: string;
  last_name: string;
  email: string;
}

export interface PutAdmin {
  id: string;
  site: string[];
  admin_role: AdminRole;
  first_name: string;
  last_name: string;
  email: string;
}

export interface Data {
  patientId: string;
  question_id: string;
  answer: string;
  createTimestamp: number
}

export interface PostData {
  question_id: string;
  answer: string;
}

export enum AdminRole {
  Tec = 'TEC',
  Investigator = 'INVESTIGATOR',
  Admin = 'ADMIN',
  TecPrelev = 'TECPRELEV',
  Arc = 'ARC'
}

export interface Question {
  description: string;
  category: string;
  sort: string;
  id: string;
  phase: string;
  step: string;
  label: string;
  type: string;
  unit: string;
  limitMaxNoRisk: string;
  limitMaxRisk: string;
  limitMinNoRisk: string;
  limitMinRisk: string;
  [AdminRole.Admin]: string;
  [AdminRole.Tec]: string;
  [AdminRole.Investigator]: string;
  [AdminRole.TecPrelev]: string;
  [AdminRole.Arc]: string;
}

export enum PatientStatus {
  Selected = 'SELECTED',
  Included = 'INCLUDED',
  Finished = 'FINISHED',
  DropOut = 'DROP OUT',
  Deleted = 'DELETED'
}

export enum PatientGroup {
  ClinicallyAtRisk = 'clinically at risk',
  NoClincallyAtRisk = 'no clinically at risk',
}

export interface Patient {
  site: string;
  patient_status: PatientStatus;
  phase: string;
  step: string;
  admin_id: string;
  id: string;
  timestamp_creation: number;
  patient_group: PatientGroup;
  rank: number;
  bmi: number;
  sex: string;
  birthdate: string;
  note: string;
  report_problem: string;
  report: boolean;
  inclusion_timestamp: number;
  expiration_timestamp: number;
  patient_part_finished: string;
  sample_validate: string;
  r24_validate: string;
  anti_lipid: 'yes' | 'no' | 'not concerned' | null;
  antihypertensives: 'yes' | 'no' | 'not concerned' | null;
  anti_obesogens: 'yes' | 'no' | 'not concerned' | null;
  menopause_status: 'pre-menopause' | 'peri-menopause' | 'post-menopause' | 'not concerned' | null;
  diet: 'yes' | 'no' | null;
  nursing: 'yes' | 'no' | 'not concerned' | null;
  transferred: boolean;
  biological_file_name: string[];
  "r24-1": string;
  "r24-2": string;
  "r24-3": string;
  finished_timestamp: number |undefined;
}

export interface PostPatient {
  patient_status: string;
  phase: string;
  step: string;
  site: string;
  bmi: number;
  sex: string;
  birthdate: string;
  note: string;
}

export interface PostSurveyTask {
  description: string;
  patientId: string;
  surveyList: string[];
  title: string;
}

export interface PutPatient {
  id: string;
  patient_status: string;
  phase: string;
  step: string;
  site: string;
  patient_group: PatientGroup;
  bmi: number;
  sex: string;
  birthdate: string;
  note: string;
  report: boolean;
  report_problem: string;
  inclusion_timestamp: number;
  expiration_timestamp: number;
  anti_lipid: 'yes' | 'no' | 'not concerned' | null,
  antihypertensives: 'yes' | 'no' | 'not concerned' | null,
  anti_obesogens: 'yes' | 'no' | 'not concerned' | null,
  menopause_status: 'pre-menopause' | 'peri-menopause' | 'post-menopause' | 'not concerned' | null,
  diet: 'yes' | 'no' | null,
  nursing: 'yes' | 'no' | 'not concerned' | null
}

export interface PatientPageLoaderData {
  patientId: string;
}

export enum SiteType {
  heal = 'soins',
  research1 = 'CIC',
  research2 =  'centre de prevention',
}

export interface Site {
  id: string;
  city: string;
  patient_number: string;
  type: SiteType;
}

export interface PutAdminRule {
  role: string,
  1: string,
  2: string,
  3: string,
  4: string,
  5: string,
  6: string,
}

export interface Query {
  id: string;
  site_id: string;
  message: string;
  timestamp: number;
  patient_id: string;
  question_id: string;
  question_answer: string;
  question: string;
  answer: string;
  timestamp_answer: number;
  query_status: 'OPEN' | 'CLOSE';
}

export interface PostQuery {
  message: string;
  patient_id: string;
  question_id: string;
  question_answer: string;
  question: string;
}

export interface PostQueryAnswer {
  query_id: string;
  answer: string;
}

export interface AdverseEvent {
  id: string;
  description: string;
  patient_id: string;
  start_date: string;
  end_date:  string;
  severity: string;
  event_type: string;
  linked: string;
  event_action: string;
  treatment: string;
  timestamp: number;
}

export interface PostAdverseEvent {
  description: string;
  patient_id: string;
  start_date: string;
  end_date:  string;
  severity: string;
  event_type: string;
  linked: string;
  event_action: string;
  treatment: string;
}

export interface PutAdverseEvent {
  id: string;
  description: string;
  patient_id: string;
  start_date: string;
  end_date:  string;
  severity: string;
  event_type: string;
  linked: string;
  event_action: string;
  treatment: string;
}

export interface ResponseKitData {
  "attributed_patients": string[];
  "sent_patients": string[];
  "shipping_patients": string[];
  "lost_patients": string[];
  "received_patients": string[];
  }