import React, { useEffect, useMemo, useState } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { usePutPatientMutation } from '../../store/api/patientApi';
import { Patient } from '../../types/types';
import { useDispatch, useSelector } from 'react-redux';
import { setPatient } from '../../store/slices';
import { patientSelector } from '../../store/selectors';

interface PatientHealthDataFormProps {
  disable: boolean;
}

const PatientHealthDataForm: React.FC<PatientHealthDataFormProps> = ({disable }) => {
  const patient = useSelector(patientSelector).patient;
  const initialFormData = useMemo(() => ({
    anti_lipid: patient.anti_lipid,
    antihypertensives: patient.antihypertensives,
    anti_obesogens: patient.anti_obesogens,
    menopause_status: patient.sex === 'man' ? "not concerned" : patient.menopause_status,
    diet: patient.diet,
    nursing: patient.sex === 'man' ? "not concerned" : patient.nursing,
  }), [patient]);

  const [formData, setFormData] = useState(initialFormData);

  const [putPatient] = usePutPatientMutation();

  const dispatch = useDispatch();

  const handleChange = (field: keyof typeof formData) => (event: SelectChangeEvent<"not concerned" | "yes" | "no" | null | 'undefined'>) => {
    let value = event.target.value
    if(value === 'undefined')
    {
      value = null
    }

    const updatedFormData = {
      ...formData,
      [field]: value,
    };

    setFormData(updatedFormData);
  };

  const handleChangeMenopauseStatus = (field: keyof typeof formData) => (event: SelectChangeEvent<"pre-menopause" | "peri-menopause" | "post-menopause" | 'not concerned' | null | 'undefined'>) => {
    const value = event.target.value;

    const updatedFormData = {
      ...formData,
      [field]: value,
    };

    setFormData(updatedFormData);
  };

  useEffect(() => {
    const updatedPatient: Patient = { ...patient, ...formData };
    putPatient(updatedPatient).unwrap().then(() => {
      dispatch(setPatient(updatedPatient));
    }).catch(error => {
      console.error('Error updating patient:', error);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formData, putPatient]);

  return (
    <form>
      <Box sx={{ backgroundColor: 'white', borderRadius: 10, margin: 5, padding: 5, boxShadow: 1 }}>
        <Box style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
          {(['anti_lipid', 'antihypertensives', 'anti_obesogens', 'diet', 'nursing'] as const).map((field) => (
            <FormControl fullWidth key={field}>
              <InputLabel>{fieldDescriptions[field]}</InputLabel>
              <Select
                label={fieldDescriptions[field]}
                value={formData[field] === null ? 'undefined' : formData[field]}
                onChange={handleChange(field)}
                disabled={(patient.sex === 'man' && ['nursing'].includes(field)) || disable}
              >
                <MenuItem value={'yes'}>Oui</MenuItem>
                <MenuItem value={'no'}>Non</MenuItem>
                <MenuItem value={'not concerned'}>Pas concerné</MenuItem>
                <MenuItem value={'undefined'}></MenuItem>
              </Select>
            </FormControl>
          ))}
          
          <FormControl fullWidth>
            <InputLabel>{fieldDescriptions['menopause_status']}</InputLabel>
            <Select
              label="Status ménopause ?"
              value={formData.menopause_status === null ? 'undefined' : formData.menopause_status}
              onChange={handleChangeMenopauseStatus('menopause_status')}
              disabled={patient.sex === 'man' || disable}
            >
              <MenuItem value={'pre-menopause'}>Pre-menopause</MenuItem>
              <MenuItem value={'post-menopause'}>Post-menopause</MenuItem>
              <MenuItem value={'not concerned'}>Pas concerné</MenuItem>
              <MenuItem value={'undefined'}></MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </form>
  );
};

// Descriptions plus détaillées pour les champs
const fieldDescriptions: { [key: string]: string } = {
  anti_lipid: 'Prise des anti-lipidiques (eg. Statines, fibrates, etc)',
  antihypertensives: 'Prise des antihypertensives (eg. diurétiques, inhibiteurs d’ACE)',
  anti_obesogens: 'Prise des anti-obésogènes (eg. GLP-1 dehors les cas de diabètes) ?',
  menopause_status: 'Status ménopause ?',
  diet: 'Régime alimentaire actuel conseillé par un professionnel de santé ?',
  nursing: 'Status allaitement ?',
};

export default PatientHealthDataForm;
