import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { CognitoErrorHandler } from '../components/error/cognito-error-handler';
import { useTranslation } from 'react-i18next';
import { signIn } from 'aws-amplify/auth/cognito';
import { useAppContext } from '../components/authentication/account';

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://integrative-phenomics.com">
        Integrative phenomics
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInAdminPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isAuthenticating, userHasAuthenticated } = useAppContext();
  const [mailError, setMailError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');

  const resetError = () => {
    setMailError('');
    setPasswordError('');
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    resetError();
    const data = new FormData(event.currentTarget);
    const mail = data.get('mail');
    const password = data.get('password');
    if (!mail) {
      setMailError(t('error.missing.mail') as string);
    }
    if (!password) {
      setPasswordError(t('error.missing.password') as string);
    }

    if (mail && password) {
      (async () => {
        try {
          const signInResult = await signIn({username: (mail as string).toLocaleLowerCase(), password: password as string});
          const signInStep = signInResult.nextStep?.signInStep;
          if (signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
            return navigate('/firstLogin');
          } else {
            const origin = location.state?.from?.pathname || '/root/dashboard';
            userHasAuthenticated(true);
            navigate(origin === '/' ? '/root/dashboard' : origin);
          }
        } catch (err: any) {
          setMailError(CognitoErrorHandler(err, t));
        }
      })();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('page.signIn.title') as string}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="mail"
              label={t('label.mail') as string}
              name="mail"
              autoComplete="mail"
              autoFocus
              error={mailError !== ''}
              helperText={mailError}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('label.password') as string}
              type="password"
              id="password"
              autoComplete="current-password"
              error={passwordError !== ''}
              helperText={passwordError}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isAuthenticating}
              sx={{ mt: 3, mb: 2 }}>
              {t('label.signIn') as string}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgotPassword" variant="body2">
                  {t('label.forgotPassword') as string}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
