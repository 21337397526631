import { ContentCopy } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

interface ButtonProps {
  textToCopy: string;
}

const CopyButton: React.FC<ButtonProps> = ({ textToCopy }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy)
  };

  return (
    <IconButton onClick={copyToClipboard} size="large" aria-label="copy">
      <ContentCopy fontSize='large' />
    </IconButton>
  );
};

export default CopyButton;