/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { adminSelector, dataSelector, patientSelector, questionSelector } from '../../store/selectors';
import { Patient, FormData, PatientStatus, AdminRole, FormError, PutAdminRule } from '../../types/types';
import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import DynamicForm from '../form/dynamic-form';
import PrintPdf from '../information/print-pdf';
import { usePostSurveyTaskMutation, usePutPatientMutation } from '../../store/api';
import HandleLoadingError from '../handle-loading-error/handle-loading-error';
import { setPatient } from '../../store/slices';
import FileUploadBiologicalDataComponent from '../form/post-biological-data';
import PatientHealthDataForm from '../form/patient-health-data-form';
import WarningIcon from '@mui/icons-material/Warning';

interface Step {
  label: string;
  step: number;
  content: React.ReactNode;
  nextButtonLabel: string;
  nextButtonDisable: boolean;
}

interface StepStyleProps {
  step: string;
}

interface StepContainerProps {
  width: string;
}

const MainContainer = styled.div`
  width: 100%;
  margin-bottom: 100px;
  padding: 0 16px;
`;

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column'
};

const StepContainer = styled.div<StepContainerProps>`
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  position: relative;
  :before {
    content: '';
    position: absolute;
    background: #f3e7f3;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: #4a154b;
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StepStyle = styled.div<StepStyleProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid ${({ step }) =>
    step === PatientStatus.Selected ? 'green' : step === 'completed' ? '#4A154B' : '#F3E7F3'};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ step }) =>step === 'completed' ? 'pointer' : 'default'};
`;

const StepCount = styled.span<StepStyleProps>`
  font-size: 19px;
  color: ${({ step }) =>
    step === PatientStatus.Selected ? 'green' : step === 'completed' ? '#4A154B' : '#F3E7F3'};
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const StepsLabelContainer = styled.div`
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StepLabel = styled.span`
  font-size: 19px;
  color: #4a154b;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;
  interface Props {
    patient: Patient;
  }

const ProgressSteps: React.FC<Props> = ({patient}) => {
  const timestamp: number = Date.now();
  const patientProfil = useSelector(patientSelector).patient;
  const [formData, setFormData] = useState<FormData>({});
  const [formError, setFormError] = useState<FormError>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openFinishModal, setOpenFinishModal] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(Number(patient.step));
  const [areHealthFieldsNotEmpty, setAreHealthFieldsNotEmpty] = useState<boolean>(false);
  const [selectedStep, setSelectedStep] = useState<number>(Number(patient.step));
  const [
    putPatient,
    { isError: isErrorPatient, isLoading: isLoadingPatient },
  ] = usePutPatientMutation();
  const [
    postSurveyTask,
    { isError: isErrorSurveyTask, isLoading: isLoadingSurveyTask },
  ] = usePostSurveyTaskMutation();
  const dispatch = useDispatch();
  
  const admin = useSelector(adminSelector);
  const questions = useSelector(questionSelector).questions;
  const data = useSelector(dataSelector).data;

  const rules = admin.adminRules.find(rule => rule.role === admin.admin_role)
  const key = String(activeStep + 1) as keyof PutAdminRule;

  useEffect(() => {
    const fieldsToCheck = [
      patientProfil.anti_lipid,
      patientProfil.antihypertensives,
      patientProfil.anti_obesogens,
      patientProfil.menopause_status,
      patientProfil.diet,
      patientProfil.nursing
    ];
    setAreHealthFieldsNotEmpty(fieldsToCheck.every(field => field !== null));
  }, [patientProfil])
  
  const isFormDataValid = (formData: FormData, step: string): boolean => {
    const stepQuestions = questions.filter(question => question.step === step)
    for (const question of stepQuestions) {
      const { id } = question;
    
      if (!(id in formData)) {
        return false;
      }
  
      if (
        (typeof formData[id] === 'number' ||
            typeof formData[id] === 'string' ||
            formData[id] instanceof Date) &&
          !formData[id]
      ) {
        return false; 
      }

      if(typeof formData[id] === 'boolean' && formData[id] === false) {
        return false;
      }
    }
    return true;
  };

  const nextStepForPatient = async ( noVerification : boolean, patient_status?: PatientStatus) => {
    const time = new Date().getTime();
    if(noVerification || isFormDataValid(formData, String(activeStep))) {
      if(Number(patient.step) === 6) {
        setOpenFinishModal(true);
      } else {
        const patientAttribut = {
          ...patient,
          patient_status: patient_status ?? patient.patient_status,
          step: String(Number(patient.step) + 1),
          inclusion_timestamp: Number(patient.step) === 3 ? time : patient.inclusion_timestamp,
          expiration_timestamp: Number(patient.step) === 3 ? time + 864000000 : patient.expiration_timestamp
        }
        await putPatient(patientAttribut).unwrap();
        dispatch(setPatient(patientAttribut));

        if(activeStep < 6){
          setSelectedStep(selectedStep + 1);
          setActiveStep(activeStep + 1)
        }
      }
    }
  };

  const nextStep = () => {
    setSelectedStep(selectedStep + 1);
  };
  
  const prevStep = () => {
    setSelectedStep(selectedStep - 1);
  };

  const hasFormErrors = Object.values(formError).filter(error => error !== '').length !== 0;
  const hasInvalidRule = Object.prototype.hasOwnProperty.call(rules, key) && rules !== undefined ? rules[key] === 'False' : true;
  const isActiveStepLoading = isLoadingPatient;

  const convertType = (localStep: number) => {
    const updatedFormData: FormData = {};
    if (localStep === 3) {
      data.forEach((item) => {
        const question = questions.filter(question => question.step === String(localStep) || question.step === '1').find((q) => q.id === item.question_id);
  
        if (question && typeof item.answer === 'string') {
          if (question.type === 'boolean') {
            updatedFormData[question.id] = item.answer === 'true';
          } else if (question.type === 'number') {
            updatedFormData[question.id] = parseFloat(item.answer);
          } else if (question.type === 'date') {
            updatedFormData[question.id] = item.answer;
          } else {
            updatedFormData[question.id] = item.answer;
          }
        }
      });
    } else if (localStep === 6) {
      data.forEach((item) => {
        const question = questions.filter(question => question.step === String(localStep) || question.step === '7').find((q) => q.id === item.question_id);
    
        if (question && typeof item.answer === 'string') {
          if (question.type === 'boolean') {
            updatedFormData[question.id] = item.answer === 'true';
          } else if (question.type === 'number') {
            updatedFormData[question.id] = parseFloat(item.answer);
          } else if (question.type === 'date') {
            updatedFormData[question.id] = item.answer;
          } else {
            updatedFormData[question.id] = item.answer;
          }
        }
      });
    }else{
      data.forEach((item) => {
        const question = questions.filter(question => question.step === String(localStep)).find((q) => q.id === item.question_id);
  
        if (question && typeof item.answer === 'string') {
          if (question.type === 'boolean') {
            updatedFormData[question.id] = item.answer === 'true';
          } else if (question.type === 'number') {
            updatedFormData[question.id] = parseFloat(item.answer);
          } else if (question.type === 'date') {
            updatedFormData[question.id] = item.answer;
          } else {
            updatedFormData[question.id] = item.answer;
          }
        }
      });
    }
    return updatedFormData;
  }

  useEffect(() => {
    setFormData(convertType(selectedStep));
  }, [data, questions, selectedStep])

  const handleFormData = (confirmData: FormData) => {
    setFormData((prevFormData) => {
      return Object.assign({}, prevFormData, confirmData);
    });
  };

  const handleFormError = (confirmError: FormError) => {
    setFormError((prevFormError) => {
      return Object.assign({}, prevFormError, confirmError);
    });
  };



  const steps: Step[] = [
    {
      label: 'Sélection',
      step: 1,
      content:  <DynamicForm loading={isLoading} setLoading={setIsLoading} patientGroup={patient.patient_group} formData={formData} questions={questions.filter(question => Number(question.step) === 1)} disable={true} setFormData={handleFormData} formError={formError} setFormError={handleFormError} />,
      nextButtonLabel: "Visite",
      nextButtonDisable: activeStep === 1 ? false : true
    },
    {
      label: 'Visite accueil',
      step: 2,
      content: (
        <>
          <PrintPdf id={patient.id} visit='11'/>
        </>),
      nextButtonLabel: "Passer à l'étape suivante",
      nextButtonDisable: !isFormDataValid(formData, String(activeStep))
    },
    {
      label: 'Inclusion',
      step: 3,
      content: <Box>
        <PatientHealthDataForm disable={activeStep!== 3} />
        <DynamicForm loading={isLoading} setLoading={setIsLoading} patientGroup={patient.patient_group} formData={formData} questions={questions.filter(question => Number(question.step) === 1)} disable={ patient.patient_status !== PatientStatus.Selected || (activeStep !== 3)} setFormData={handleFormData} formError={formError} setFormError={handleFormError}/>
        <DynamicForm loading={isLoading} setLoading={setIsLoading} patientGroup={patient.patient_group} formData={formData} questions={questions.filter(question => Number(question.step) === 3)} disable={ patient.patient_status !== PatientStatus.Selected || (activeStep !== 3)} setFormData={handleFormData} formError={formError} setFormError={handleFormError}/>
      </Box>,
      nextButtonLabel: 'Inclure',
      nextButtonDisable: activeStep === 3 ? (!isFormDataValid(formData, String(activeStep)) || !isFormDataValid(formData, String(1)) || !areHealthFieldsNotEmpty) : true,
    },
    {
      label: 'Visite recueil',
      step: 4,
      content: <Box>
        <DynamicForm loading={isLoading} setLoading={setIsLoading} patientGroup={patient.patient_group} formData={formData} questions={questions.filter(question => Number(question.step) === 4)} disable={ (patient.patient_status !== PatientStatus.Included && patient.patient_status !== PatientStatus.Finished) || patient.finished_timestamp && patient.finished_timestamp <= timestamp / 1000 ? true : false} setFormData={handleFormData} formError={formError} setFormError={handleFormError}/>
      </Box>,
      nextButtonLabel: "Passer à l'étape suivante",
      nextButtonDisable: !isFormDataValid(formData, String(activeStep))
    },
    {
      label: 'Biologique',
      step: 5,
      content: <Box>
        <DynamicForm loading={isLoading} setLoading={setIsLoading} patientGroup={patient.patient_group} formData={formData} questions={questions.filter(question => Number(question.step) === 5)} disable={ (patient.patient_status !== PatientStatus.Included && patient.patient_status !== PatientStatus.Finished) || patient.finished_timestamp && patient.finished_timestamp <= timestamp / 1000 ? true : false} setFormData={handleFormData} formError={formError} setFormError={handleFormError}/>
      </Box>,
      nextButtonLabel: "Passer à l'étape suivante",
      nextButtonDisable: !isFormDataValid(formData, String(activeStep))
    },
    {
      label: 'Finalisation',
      step: 6,
      content: <Box>
        <FileUploadBiologicalDataComponent formData={formData} setFormData={handleFormData}  patientId={patient.id}/> 
        <DynamicForm loading={isLoading} setLoading={setIsLoading} patientGroup={patient.patient_group} formData={formData} questions={questions.filter(question => Number(question.step) === 7)} disable={ (patient.patient_status !== PatientStatus.Included && patient.patient_status !== PatientStatus.Finished) || patient.finished_timestamp && patient.finished_timestamp <= timestamp / 1000 ? true : false} setFormData={handleFormData} formError={formError} setFormError={handleFormError}/>
        <DynamicForm loading={isLoading} setLoading={setIsLoading} patientGroup={patient.patient_group} formData={formData} questions={questions.filter(question => Number(question.step) === 6)} disable={ (patient.patient_status !== PatientStatus.Included && patient.patient_status !== PatientStatus.Finished) || (patient.finished_timestamp && patient.finished_timestamp <= timestamp / 1000 ? true : false)} setFormData={handleFormData} formError={formError} setFormError={handleFormError}/>
      </Box>,
      nextButtonLabel: "Fini",
      nextButtonDisable: activeStep === 6 ? (!isFormDataValid(convertType(4), String(4)) || !isFormDataValid(formData, String(activeStep)) || !isFormDataValid(convertType(5), String(5))) : true
    },
  ];
  
  const totalSteps: number = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  return (
    <Box>
      <HandleLoadingError isError={isErrorPatient || isErrorSurveyTask} isLoading={false} >
        <MainContainer>
          <StepContainer width={width}>
            {steps.map(({ step, label }: Step) => (
              <StepWrapper key={step}>
                <StepStyle onClick={() => {
                  activeStep >= step && setSelectedStep(step);
                }} step={step === selectedStep ? PatientStatus.Selected : activeStep >= step ? 'completed' : 'incomplete'}>
                  {activeStep > step ? (
                    <Check sx={{color: step === selectedStep ? 'green' : '#4A154B' }} />
                  ) : (
                    <StepCount step={step === selectedStep ? PatientStatus.Selected : activeStep >= step ? 'completed' : 'incomplete'}>{step}</StepCount>
                  )}
                </StepStyle>
                <StepsLabelContainer>
                  <StepLabel key={step}>{label}</StepLabel>
                </StepsLabelContainer>
              </StepWrapper>
            ))}
          </StepContainer>
        </MainContainer>
        <Box>
          {steps[selectedStep - 1].content}
          <Box sx={{margin: 5}}>
            {
              isLoadingPatient || isLoadingSurveyTask ? (
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}} >
                  <CircularProgress sx={{margin: 2}} />
                </Box>
              ) : patient.patient_status !== PatientStatus.Included && patient.patient_status !== PatientStatus.Selected ? (
                <Box sx={{display: 'flex', justifyContent: 'space-around'}} >
                  { selectedStep !== 1 && <Button onClick={prevStep} variant='contained'>Précédent</Button>}
                  { selectedStep !== 6 && <Button onClick={nextStep} variant='contained'>Suivant</Button>}
                </Box>
              ) : (
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}} >
                  {Object.values(formError).filter(error => error !== '').length !== 0 && <Typography sx={{marginX: 3}} variant='body2' color={'red'}>Des valeurs semblent être incorrectes {Object.values(formError).filter(error => error !== '').length}</Typography>}
                  {
                    isLoading ? <CircularProgress sx={{margin: 2}} /> :
                      <Button disabled={hasFormErrors || hasInvalidRule || isActiveStepLoading || steps[selectedStep - 1].nextButtonDisable} onClick={() => nextStepForPatient(activeStep === 1 ? true : false, activeStep === 3 ? PatientStatus.Included : undefined)} variant='contained'>{steps[activeStep - 1].nextButtonLabel}</Button>
                  }
                </Box>
              )
            }
          </Box>
        </Box>
        <Modal
          open={openFinishModal}
          onClose={() => setOpenFinishModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Box sx={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: 2}}>
              <WarningIcon color='error' sx={{ fontSize: 40 }} />
              <Typography sx={{textAlign: 'center'}} color='error'>Attention, cette action a des effets irréversibles (les données seront verrouillées)</Typography>
              <Typography id="modal-modal-title" sx={{textAlign: 'center'}} variant="h6" component="h2">
          Êtes-vous sûr ?
              </Typography>
              <Box>
                <Button onClick={async ()=> {
                  const patientAttribut = {
                    ...patient,
                    patient_status: PatientStatus.Finished,
                    step: Number(patient.step) < 6 ? String(Number(patient.step) + 1) : '6',
                  }
                  await putPatient(patientAttribut).unwrap();
                  dispatch(setPatient(patientAttribut));
                  setOpenFinishModal(false);
                }} color='info'>oui</Button>
                <Button onClick={() => setOpenFinishModal(false)} color='error'>non</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </HandleLoadingError>
    </Box>
  );
};
  
export default ProgressSteps;