/* eslint-disable @typescript-eslint/ban-types */
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../../utils/api';
import { PostSurveyTask } from '../../types/types';

export const surveyApi = createApi({
  reducerPath: 'surveyApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Survey'],
  endpoints: (builder) => ({
    postSurveyTask: builder.mutation<
      {},
      PostSurveyTask
    >({
      query: ({ description, patientId, surveyList, title }) => ({
        url: `/admin/patient/${patientId}/task`,
        method: 'POST',
        body: JSON.stringify({
          description,
          'survey_list' : surveyList,
          title,
        }),
      }),
    }),
  }),
});

export const { usePostSurveyTaskMutation } = surveyApi;
