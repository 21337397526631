import React from 'react';
import { Box, Button, Checkbox, CircularProgress, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { usePostAdminMutation } from '../../store/api';
import { adminSelector } from '../../store/selectors';
import { useSelector } from 'react-redux';
import { AdminRole, PostAdmin } from '../../types/types';
import { siteSelector } from '../../store/selectors/siteSelector';
  
const initialFormPatient: PostAdmin = {
  site: [],
  admin_role: AdminRole.Investigator,
  first_name: '',
  last_name: '',
  email: '',
};

interface Props {
  onSubmit: () => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateAdmin : React.FC<Props> = ({onSubmit}) => {
  const [formAdmin, setFormPatient] = React.useState<PostAdmin>(initialFormPatient);
  const [error, setError] = React.useState<string>('');
  const sites = useSelector(siteSelector).sites;
  const admin = useSelector(adminSelector)
  const [
    postAdmin,
    { isLoading: isLoadingAdmin },
  ] = usePostAdminMutation();

  const handleChangePatient = (event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormPatient((prevData) => ({
      ...prevData,
      [name as string]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newAdminSite = sites.filter(site => formAdmin.site.includes(site.city)).map(site => site.id);
    const responsePatient = await postAdmin({
      site: newAdminSite,
      admin_role: formAdmin.admin_role,
      first_name: formAdmin.first_name,
      last_name: formAdmin.last_name,
      email: formAdmin.email.toLocaleLowerCase(),
    }).unwrap();
    setError('');

    if(responsePatient['message'] === 'Email already exists in the User Pool.'){
      setError('Cette email est déjà utilisé');
    } else {
      onSubmit()
    }
  };

  const handleChange = (event: SelectChangeEvent<typeof formAdmin.site>) => {
    const {
      target: { value },
    } = event;
    setFormPatient((prevData) => ({
      ...prevData,
      site: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        backgroundColor: 'white',
        boxShadow: 1,
        borderRadius: 5,
        marginX: 3,
        padding: 3,
      }}>
      <h4>Créer un Administrateur</h4>
      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="site-label">Centre</InputLabel>
              <Select
                labelId="site-label"
                id="site"
                name="site"
                label="Centre"
                multiple
                value={formAdmin.site}
                onChange={handleChange}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {sites.filter(site => admin.admin_role !== AdminRole.Admin ? admin.site.includes(site.id) : true ).map(site => (
                  <MenuItem key={site.id} value={site.city}>
                    <Checkbox checked={formAdmin.site.indexOf(site.city) > -1} />
                    <ListItemText primary={site.city} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} >
            <FormControl fullWidth>
              <InputLabel id="user-role-label">Role de l&apos;utilisateur</InputLabel>
              <Select
                labelId="user-role-label"
                id="admin_role"
                name="admin_role"
                label="Role de l'utilisateur"
                value={formAdmin.admin_role}
                onChange={handleChangePatient}
                fullWidth
              >
                <MenuItem value={AdminRole.Tec}>Tec</MenuItem>
                <MenuItem value={AdminRole.Investigator}>Investigateur</MenuItem>
                <MenuItem value={AdminRole.Admin}>Administrateur</MenuItem>
                <MenuItem value={AdminRole.TecPrelev}>Technicien de prélèvement</MenuItem>
                <MenuItem value={AdminRole.Arc}>Arc</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} >
            <FormControl fullWidth>
              <Box display="flex" alignItems="center">
                <TextField
                  type="text"
                  label="Prénom"
                  name="first_name"
                  id="first_name"
                  value={formAdmin.first_name}
                  onChange={handleChangePatient}
                  fullWidth
                />
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={12} >
            <FormControl fullWidth>
              <Box display="flex" alignItems="center">
                <TextField
                  type="last_name"
                  label="Nom"
                  name="last_name"
                  id="last_name"
                  value={formAdmin.last_name}
                  onChange={handleChangePatient}
                  fullWidth
                />
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={12} >
            <FormControl fullWidth>
              <Box display="flex" alignItems="center">
                <TextField
                  type="email"
                  label="Email"
                  name="email"
                  id="email"
                  value={formAdmin.email}
                  onChange={handleChangePatient}
                  fullWidth
                />
              </Box>
            </FormControl>
          </Grid>
        </Grid>
        {error && <Typography color={'red'}>{error}</Typography>}
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Button disabled={isLoadingAdmin || Object.values(formAdmin).includes('')} sx={{marginTop: 2, alignSelf: 'center'}} type="submit" variant="contained" color="primary" fullWidth>
            Créer un admin
          </Button>
          {isLoadingAdmin && <CircularProgress size={40} sx={{marginX: 4}}/>}
        </Box>
      </form>
    </Box>
  );
}

export default CreateAdmin;