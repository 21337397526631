import { createApi } from '@reduxjs/toolkit/query/react';
import { AdverseEvent, PostAdverseEvent, PutAdverseEvent } from '../../types/types';
import { baseQueryWithReauth } from '../../utils/api';

export const adverseEventApi = createApi({
  reducerPath: 'adverseEventApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAdverseEvent: builder.mutation<
      AdverseEvent[],
      string
    >({
      query: (patient_id) =>
        `/admin/adverseEvent/${patient_id}`,
    }),
    postAdverseEvent: builder.mutation<
      AdverseEvent,
      PostAdverseEvent
    >({
      query: (adverseEvent) => ({
        url: `/admin/adverseEvent`,
        method: 'POST',
        body: JSON.stringify(adverseEvent),
      }),
    }),
    putAdverseEvent: builder.mutation<
      string,
      PutAdverseEvent
    >({
      query: (adverseEvent) => ({
        url: `/admin/adverseEvent`,
        method: 'PUT',
        body: JSON.stringify(adverseEvent),
      }),
    }),
  }),
});

export const {
  useGetAdverseEventMutation,
  usePostAdverseEventMutation,
  usePutAdverseEventMutation
} = adverseEventApi;
