import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  FormControl,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
  SelectChangeEvent,
  Typography,
  CircularProgress,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import { useLoaderData } from 'react-router-dom';
import { AdverseEvent, PatientPageLoaderData } from '../../types/types';
import { usePostAdverseEventMutation, usePutAdverseEventMutation } from '../../store/api';
import { useDispatch, useSelector } from 'react-redux';
import { setAdverseEvent } from '../../store/slices/adverseEventSlice';
import { adverseEventSelector } from '../../store/selectors/adverseEventSelector';

interface Props {
  open: boolean;
  handleClose: () => void;
  put: boolean;
  adverseEvent?: AdverseEvent;
}

function formatDateDisplayToDatabase(dateString: string): string {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}

function formatDateDatabaseToDisplay(dateStr:string) {
  const parts = dateStr.split('-');
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
}

const AdverseEventModal: React.FC<Props> = ({ open, handleClose, put, adverseEvent }) => {
  const dispatch = useDispatch();
  const loaderData = useLoaderData() as PatientPageLoaderData;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    description: adverseEvent ? adverseEvent.description : '',
    startDate: adverseEvent ? formatDateDatabaseToDisplay(adverseEvent.start_date) : '',
    endDate: adverseEvent ? formatDateDatabaseToDisplay(adverseEvent.end_date) : '',
    severity: adverseEvent ? adverseEvent.severity : '',
    event_type: adverseEvent ? adverseEvent.event_type : '',
    linked: adverseEvent ? adverseEvent.linked : '',
    event_action: adverseEvent ? adverseEvent.event_action : '',
    treatment: adverseEvent ? adverseEvent.treatment : ''
  });
  const adverseEventArray = useSelector(adverseEventSelector).adverseEvent;
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if(event.target.checked) {
      setFormValues({
        ...formValues,
        endDate: ''
      });
    }
  };

  useEffect(() => {
    setFormValues({
      description: adverseEvent ? adverseEvent.description : '',
      startDate: adverseEvent ? formatDateDatabaseToDisplay(adverseEvent.start_date) : '',
      endDate: adverseEvent && adverseEvent?.end_date !== '' ? formatDateDatabaseToDisplay(adverseEvent.end_date) : '',
      severity: adverseEvent ? adverseEvent.severity : '',
      event_type: adverseEvent ? adverseEvent.event_type : '',
      linked: adverseEvent ? adverseEvent.linked : '',
      event_action: adverseEvent ? adverseEvent.event_action : '',
      treatment: adverseEvent ? adverseEvent.treatment : ''
    })
    setChecked(adverseEvent?.end_date === '' ? true : false)
  },[adverseEvent, open])

  const [
    postAdverseEvent,
  ] = usePostAdverseEventMutation();

  const [
    putAdverseEvent,
  ] = usePutAdverseEventMutation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name as string]: value
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      if(typeof formValues.startDate === 'string' && typeof formValues.endDate === 'string' ){
        if(put && adverseEvent) {
          await putAdverseEvent({...formValues, id: adverseEvent.id, start_date: formatDateDisplayToDatabase(formValues.startDate), end_date: !checked ? formatDateDisplayToDatabase(formValues.endDate) : '', patient_id: loaderData.patientId as string }).unwrap()
          const adverseEventArrayCopy = [...adverseEventArray.filter(item => item.id !== adverseEvent.id), {...adverseEvent, formValues, id: adverseEvent.id, start_date: formatDateDisplayToDatabase(formValues.startDate), end_date: !checked ? formatDateDisplayToDatabase(formValues.endDate) : ''}];
          dispatch(setAdverseEvent([...adverseEventArrayCopy.sort((a, b) => b.timestamp - a.timestamp)]));
        } else {
          const response = await postAdverseEvent({...formValues, start_date: formatDateDisplayToDatabase(formValues.startDate), end_date: !checked ? formatDateDisplayToDatabase(formValues.endDate) : '', patient_id: loaderData.patientId as string }).unwrap()
          const adverseEventArrayCopy = [...adverseEventArray, response];
          dispatch(setAdverseEvent([...adverseEventArrayCopy.sort((a, b) => b.timestamp - a.timestamp)]));
        }
        handleClose()
      }
    } catch (error) {
      console.error('Erreur lors de la soumission des données:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormComplete = Object.entries(formValues).every(([key, value]) => {
    if (key === 'endDate') {
      return checked ? value === '' : (value !== '' && value !== null);
    }
    if(key === 'treatment') {
      return true
    }
    return value !== '' && value !== null;
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ backgroundColor: '#1976d2', color: 'white' }}>
        Créer un evènement indésirable
      </DialogTitle>
      <form style={{padding: 20}} onSubmit={handleSubmit}>
        <TextField
          name="description"
          label="Description de l’EI"
          variant="outlined"
          fullWidth
          multiline
          margin="normal"
          value={formValues.description}
          onChange={handleInputChange}
          rows={4}
          inputProps={{maxLength: 300}}
        />
        <Typography variant='body1'>300 caractères maximum</Typography>
        <FormControl sx={{marginY: 2}} fullWidth>
          <TextField
            type="date"
            id="startDate"
            name="startDate"
            label="Date de début"
            value={formValues.startDate}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </FormControl>
        <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange}/>} label="En cours" />
        <FormControl sx={{marginY: 2}} fullWidth>
          <TextField
            type="date"
            id="endDate"
            name="endDate"
            label="Date de fin"
            value={formValues.endDate}
            disabled={checked}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </FormControl>
        <FormControl component="fieldset" margin="normal" fullWidth>
          <FormLabel component="legend">Sévérité de l’EI</FormLabel>
          <RadioGroup
            name="severity"
            value={formValues.severity}
            onChange={handleInputChange}
            row
          >
            <FormControlLabel value="0" control={<Radio />} label="Aucune" />
            <FormControlLabel value="1" control={<Radio />} label="Légère" />
            <FormControlLabel value="2" control={<Radio />} label="Modérée" />
            <FormControlLabel value="3" control={<Radio />} label="Sévère" />
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" margin="normal" fullWidth>
          <FormLabel component="legend">Type d’EI</FormLabel>
          <RadioGroup
            name="event_type"
            value={formValues.event_type}
            onChange={handleInputChange}
            row
          >
            <FormControlLabel value="grave" control={<Radio />} label="Grave" />
            <FormControlLabel value="non-grave" control={<Radio />} label="Non grave" />
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Évaluation de la relation avec l’étude</FormLabel>
          <RadioGroup
            name="linked"
            value={formValues.linked}
            onChange={handleInputChange}
            row
          >
            <FormControlLabel value="relié" control={<Radio />} label="Relié" />
            <FormControlLabel value="non-relié" control={<Radio />} label="Non relié" />
          </RadioGroup>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="action-label">Action prise</InputLabel>
          <Select
            labelId="action-label"
            name="event_action"
            label="Action prise"
            value={formValues.event_action}
            onChange={handleSelectChange}
          >
            <MenuItem value="0">Aucune</MenuItem>
            <MenuItem value="1">Modification d’un traitement en cours</MenuItem>
            <MenuItem value="2">Nouveau traitement concomitant</MenuItem>
            <MenuItem value="3">Hospitalisation</MenuItem>
            <MenuItem value="4">Arrêt de l’étude</MenuItem>
          </Select>
        </FormControl>

        <TextField
          name="treatment"
          label="Traitement utilisé, le cas échéant"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formValues.treatment}
          onChange={handleInputChange}
        />
        <FormHelperText>Ce champ n&apos;est pas obligatoire</FormHelperText>
        <Button sx={{marginTop: 2}} variant="contained" color="primary" type="submit" disabled={!isFormComplete || isSubmitting}>
          {isSubmitting ? <CircularProgress size={24} /> : 'Soumettre'}
        </Button>
      </form>
    </Dialog>
  );
};

export default AdverseEventModal;
