import { ResponseKitData } from "../types/types";

export const filterResponseKitData = (
  data: ResponseKitData,
  verificationArray: string[]
): ResponseKitData => {
  return {
    attributed_patients: data.attributed_patients.filter(patient =>
      verificationArray.includes(patient)
    ),
    sent_patients: data.sent_patients.filter(patient =>
      verificationArray.includes(patient)
    ),
    shipping_patients: data.shipping_patients.filter(patient =>
      verificationArray.includes(patient)
    ),
    lost_patients: data.lost_patients.filter(patient =>
      verificationArray.includes(patient)
    ),
    received_patients: data.received_patients.filter(patient =>
      verificationArray.includes(patient)
    ),
  };
};