import { createApi } from '@reduxjs/toolkit/query/react';
import { Data, PostData, ResponseKitData } from '../../types/types';
import { baseQueryWithReauth } from '../../utils/api';

export const dataApi = createApi({
  reducerPath: 'dataApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getDataByPatient: builder.mutation<
      Data[],
      string
    >({
      query: ( id ) =>
        `/admin/patient/${id}/data`,
    }),
    postData: builder.mutation<
      {message: string},
      {id: string, data: PostData}
    >({
      query: ({ id, data }) => ({
        url: `/admin/patient/${id}/data`,
        method: 'POST',
        body: JSON.stringify({
          "answer": data.answer,
          "question_id": data.question_id,
        }),
      }),
    }),
    getAnalyseDataByPatient: builder.mutation<
      {data: Data[], patient_id: string},
      string
    >({
      query: ( id ) =>
        `/admin/analyse/${id}`,
    }),
    postAnalyseData: builder.mutation<
      string,
      {id: string, data: PostData}
    >({
      query: ({ id, data }) => ({
        url: `/admin/patient/${id}/data/analyse`,
        method: 'POST',
        body: JSON.stringify({
          "answer": data.answer,
          "question_id": data.question_id,
        }),
      }),
    }),
    postBiologicalData: builder.mutation<
      string,
      {id: string, file_content: string, file_name: string}
    >({
      query: ({id, file_content, file_name}) => ({
        url: `/admin/patient/${id}/data/biological`,
        method: 'POST',
        body: JSON.stringify({
          file_content: file_content,
          file_name: file_name,
        }),
      })
    }),
    getBiologicalData: builder.mutation<
      {url :string},
      {id: string, file_name: string}
    >({
      query: ({id, file_name}) => ({
        url: `/admin/patient/${id}/data/biological/${file_name}`,
        method: 'GET'
      })
    }),
    deleteBiologicalData: builder.mutation<
      void,
      {id: string, file_name: string}
    >({
      query: ({id, file_name}) => ({
        url: `/admin/patient/${id}/data/biological/${file_name}`,
        method: 'DELETE',
      })
    }),
    getDataKitData: builder.mutation<
      ResponseKitData,
      void
    >({
      query: () => ({
        url: `/admin/analyse/data`,
        method: 'GET',
      })
    }),
  }),
});

export const {
  useGetDataByPatientMutation,
  usePostDataMutation,
  useGetAnalyseDataByPatientMutation,
  usePostAnalyseDataMutation,
  usePostBiologicalDataMutation,
  useGetBiologicalDataMutation,
  useDeleteBiologicalDataMutation,
  useGetDataKitDataMutation
} = dataApi;
