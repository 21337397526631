import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useGetDataKitDataMutation, useGetPatientsMutation } from '../store/api';
import { setKitData, setPatients } from '../store/slices';
import { useDispatch, useSelector } from 'react-redux';
import { adminSelector, dataSelector, patientSelector } from '../store/selectors';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import CircularProgressWithLabel from '../components/progress/circular-progress-with-label';
import ProgressTask from '../components/progress/progress-task';
import DasboardCardInformation from '../components/information/dashbaord-card-information';
import { AdminRole, Patient, PatientGroup, PatientStatus, ResponseKitData } from '../types/types';
import { useNavigate } from 'react-router-dom';
import { siteSelector } from '../store/selectors/siteSelector';
import { useAppContext } from '../components/authentication/account';
import { Tab, Tabs } from '@mui/material';
import HandleLoadingError from '../components/handle-loading-error/handle-loading-error';
import { filterResponseKitData } from '../utils/utils';

const DashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(adminSelector);
  const patients = useSelector(patientSelector).patients;
  const kitData = useSelector(dataSelector).kitData;
  const sites = useSelector(siteSelector).sites.filter( site => user.admin_role === AdminRole.Admin ? true : user.site.includes(site.id));
  const { setIsError } = useAppContext();
  const [selectedSite, setSelectedSite] = useState<string>(sites[0].id);
  const [filteredPatient, setFilteredPatient] = useState<Patient[]>([]);
  const [filteredKitData, setFilteredKitData] = useState<ResponseKitData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const GlobalGoal = selectedSite === 'all' ? sites.reduce((sum, site) => sum + Number(site.patient_number), 0) : Number(sites.find(site => site.id === selectedSite)?.patient_number) ?? 3000;

  const [getPatients] = useGetPatientsMutation();
  const [getDataKit] = useGetDataKitDataMutation();
  const width = window.innerWidth;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [kitDataResponse, dataPatients] = await Promise.all([
          getDataKit().unwrap(),
          patients.length === 0 ? getPatients().unwrap() : Promise.resolve(null),
        ]);
        dispatch(setKitData(kitDataResponse));
        if (dataPatients) {
          dispatch(setPatients(dataPatients));
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    (patients.length === 0 || kitData === undefined) && fetchData();
  }, [dispatch, getDataKit, getPatients, kitData, patients.length, setIsError])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedSite(newValue);
  };

  useEffect(() => {
    const listPatient = patients.filter(patient => selectedSite === 'all' ? true : patient.site === selectedSite )
    setFilteredPatient(listPatient)
    kitData && setFilteredKitData(filterResponseKitData(kitData, listPatient.map(patient => patient.id)))
  }, [kitData, patients, selectedSite])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: width * 0.1 },
    { field: 'phase', headerName: 'Phase', width: width * 0.04  },
    { field: 'patient_group', headerName: 'Groupe', width: width * 0.12, 
      renderCell: (params) => {
        switch (params.value) {
        case PatientGroup.ClinicallyAtRisk:
          return 'Cliniquement à risque';
        case PatientGroup.NoClincallyAtRisk:
          return'Pas cliniquement a risque';
        default:
          return 'inconnu'
        }  }},
    { field: 'rank', headerName: 'Rang', width: width * 0.04  },
    { field: 'site', headerName: 'Site', width: width * 0.07  },
    {
      field: 'timestamp_creation',
      headerName: 'Date de création',
      width: width * 0.09 ,
      renderCell: (params) => {
        const date = new Date(params.value * 1000);
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: 'UTC'
        };
        const formattedDate = date.toLocaleString('fr-FR', options);
        return (
          <div>
            <span>{formattedDate}</span>
          </div>
        );
      }
    },
    { field: 'step', headerName: 'Progression', width: width * 0.15,
      renderCell: (params) => {
        // Composant personnalisé à afficher dans la cellule
        return (
          <Box sx={{width: '100%',display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <LinearProgress sx={{width: '70%', marginRight: 4}} variant="determinate" value={patients.find(value => value.id === params.id)?.patient_status === PatientStatus.Finished ? 100 : Number(params.value) / 7 * 100} />
            <h6 style={{ margin: '0' }}>{patients.find(value => value.id === params.id)?.patient_status === PatientStatus.Finished ? 100 : (Number(params.value) / 7 * 100).toFixed(0)}%</h6>
          </Box>
        );
      }
    },
    { field: 'patient_status', headerName: 'Statut', width: width * 0.1,
      renderCell: (params) => {
        let color;
        switch (params.value) {
        case PatientStatus.Selected:
          color= 'yellow';
          break;
        case PatientStatus.Included:
          color= 'green';
          break;
        case PatientStatus.Finished:
          color= 'grey';
          break;
        case PatientStatus.Deleted:
          color = 'red';
          break;
        case PatientStatus.DropOut:
          color= 'orange';
          break;
        default:
          color= 'purple'
        }
        return (
          <Box sx={{width: '100%',display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Box sx={{
              width: '10px',
              height: '10px',
              backgroundColor: color,
              borderRadius: '50%'
            }} />
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 1 }}>
              <h6 style={{ margin: '0' }}>{params.value}</h6>
            </Box>
          </Box>
        );
      } 
    },
    {
      field: 'last_modification_timestamp',
      headerName: 'Date de modification',
      width: width * 0.1 ,
      renderCell: (params) => {
        const date = new Date(params.value * 1000);
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: 'UTC'
        };
        const formattedDate = date.toLocaleString('fr-FR', options);
        return (
          <div>
            <span>{formattedDate}</span>
          </div>
        );
      }
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 3,
      }}>
      <HandleLoadingError isError={false} isLoading={isLoading} >
        <Box sx={{ display: 'flex', width: width * 0.90, boxShadow: 1, borderRadius: 3, backgroundColor: 'white', paddingX: 2}}>
          <Tabs
            value={selectedSite}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab value="all" label="Tous" />
            {
              sites.map(site => (
                <Tab value={site.id} key={site.id} label={site.city} />
              ))
            }
          </Tabs>
        </Box>
        <Box 
          sx={{display: 'flex', flexDirection: width < 1200 ? 'column' : 'row'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 2,
              backgroundColor: 'white',
              boxShadow: 1,
              borderRadius: 5,
              marginY: 3,
              marginRight: 3,
            }}
          >
            <Box sx={{height: 200, backgroundColor: 'blue', borderRadius: 5, margin: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
              <CircularProgressWithLabel value={filteredPatient.filter(patient => patient.patient_status === PatientStatus.Included || patient.patient_status === PatientStatus.Finished).length/GlobalGoal * 100} size={150} sx={{color: 'red'}} />
              <Box>
                <h2 style={{color: 'white'}}>Progression globale</h2>
                <h2 style={{color: 'white'}}>{filteredPatient.filter(patient => patient.patient_status === PatientStatus.Included || patient.patient_status === PatientStatus.Finished).length}/{GlobalGoal}</h2>
              </Box>
              <img src={`/assets/img/png/doctor.png`} alt="Description de l'image" style={{width: 125, height: 125}} />
            </Box>
            <Box sx={{padding: 2}}>
              <Grid container spacing={3}>
                <Grid item xs={6}><ProgressTask progress={(filteredPatient.filter(patient => patient.report ).length/filteredPatient.filter(patient => Number(patient.step) > 3).length)*100} title={'Nombre de questionnaires complétés/attribués'} taskCompleted={filteredPatient.filter(patient => patient.report ).length} task={filteredPatient.filter(patient => Number(patient.step) > 3).length} /></Grid>
                <Grid item xs={6}><ProgressTask progress={(filteredPatient.filter(patient => patient.r24_validate === 'true' ).length/filteredPatient.filter(patient => Number(patient.step) > 3).length)*100} title={'Nombre de questionnaires R24 effectués/attribués'} taskCompleted={filteredPatient.filter(patient => patient.r24_validate === 'true' ).length} task={filteredPatient.filter(patient => Number(patient.step) > 3).length}  /></Grid>
                {filteredKitData && <Grid item xs={6}><ProgressTask progress={(filteredKitData?.attributed_patients.length === 0 ? 0 : filteredKitData?.sent_patients.length/filteredKitData?.attributed_patients.length)* 100} title={"Nombre de kits envoyés/attribués"} taskCompleted={filteredKitData?.sent_patients.length} task={filteredKitData?.attributed_patients.length}  /></Grid>}
                {filteredKitData && <Grid item xs={6}><ProgressTask progress={(filteredKitData?.sent_patients.length === 0 ? 0 : filteredKitData?.received_patients.length/filteredKitData?.sent_patients.length)* 100} title={"Nombre de kits reçus/envoyés"} taskCompleted={filteredKitData?.received_patients.length} task={filteredKitData?.sent_patients.length}  /></Grid>}
                <Grid item xs={6}><ProgressTask progress={(filteredPatient.filter(patient => patient.biological_file_name && patient.biological_file_name.length > 0 ).length/filteredPatient.filter(patient => Number(patient.step) > 5).length)*100} title={'Nombre de bilans biologiques téléchargés/ prélevés'} taskCompleted={filteredPatient.filter(patient => patient.biological_file_name && patient.biological_file_name.length > 0 ).length} task={filteredPatient.filter(patient => Number(patient.step) > 5).length}  /></Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              marginY: 3,
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={6}><DasboardCardInformation color='blue' title='Nb de personnes séléctionnées' number={filteredPatient.filter(patient => patient.patient_status === PatientStatus.Selected).length} label={'Séléctionnées'}/></Grid>
              <Grid item xs={6}><DasboardCardInformation color='green' title='Nb de personnes incluses' number={filteredPatient.filter(patient => patient.patient_status === PatientStatus.Included || patient.patient_status === PatientStatus.Finished).length} label={'Incluses'}/></Grid>
              <Grid item xs={6}><DasboardCardInformation color='orange' title='Nb de personnes perdues de vue' number={filteredPatient.filter(patient => patient.patient_status === PatientStatus.DropOut).length} label={'Perdues de vue'}/></Grid>
              <Grid item xs={6}><DasboardCardInformation color='red' title='Nb de personnes supprimées' number={filteredPatient.filter(patient => patient.patient_status === PatientStatus.Deleted).length} label={'Supprimées'}/></Grid>
            </Grid>
          </Box>
        </Box>
      </HandleLoadingError>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          backgroundColor: 'white',
          boxShadow: 1,
          borderRadius: 5,
          paddingBottom: 2,
        }}
      ><DataGrid
          onRowClick={(row) => {
            navigate('/root/patient/' + row.id);
          }}
          rows={patients.filter(patient => selectedSite === 'all' ? true : patient.site === selectedSite).slice(0, 5).sort((a, b) => b.timestamp_creation - a.timestamp_creation)}
          loading={isLoading}
          columns={columns}
          hideFooter={true}
          sx={{
            height: patients.length === 0 ? 400 : 'auto',
            border: 'none'
          }}
        /></Box>
    </Box>
  );
}

export default DashboardPage;
