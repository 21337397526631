import React from 'react';
import Box from '@mui/material/Box';

interface Props {
  number: number;
  label: string;
  title: string;
  color: string;
}
const DasboardCardInformation: React.FC<Props> = ({ number, title, label, color }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        backgroundColor: 'white',
        boxShadow: 1,
        borderRadius: 2,
        flexDirection: 'column',
        padding: 4,
        height: '100%'
      }}
    >
      <Box sx={{background: color ?? 'red', borderRadius: 3, width: '70%', height: 100, alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <Box sx={{backgroundColor: 'transparent', border: '2px solid white', borderRadius: 3, width: '80%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><h2 style={{color: 'white'}}>{number}</h2></Box>
      </Box>
      <p>{label}</p>
      <h4>{title}</h4>
    </Box>
  );
}


export default DasboardCardInformation;
