import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { CognitoErrorHandler } from '../components/error/cognito-error-handler';
import { useTranslation } from 'react-i18next';
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth/cognito';

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://integrative-phenomics.com">
        Integrative phenomics
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [codeSent, setCodeSent] = React.useState(false);
  const [mailError, setMailError] = React.useState('');
  const [codeError, setCodeError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [confirmPasswordError, setConfirmPasswordError] = React.useState('');
  const [email, setEmail] = React.useState('');

  const resetError = () => {
    setMailError('');
    setCodeError('');
    setPasswordError('');
    setConfirmPasswordError('');
  };

  const handleSubmitForgotPassword = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    resetError();
    const form = event.currentTarget;
    const data = new FormData(event.currentTarget);
    const mail = data.get('mail');
    if (!mail) {
      setMailError(t('error.missing.mail') as string);
    }
    if (mail) {
      (async () => {
        try {
          await resetPassword({username: (mail as string).toLocaleLowerCase()});
          setCodeSent(true);
          setEmail(mail as string)
          form.reset();
        } catch (err: Error | any) {
          console.log(err);
          setMailError(CognitoErrorHandler(err, t));
        }
      })();
    }
  };

  const handleSubmitConfirmPassword = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    resetError();
    const data = new FormData(event.currentTarget);
    const code = data.get('code');
    const password = data.get('password');
    const confirmPasswordData = data.get('confirmPassword');

    if (!code) {
      setCodeError(t('error.missing.code') as string);
    }
    if (!password) {
      setPasswordError(t('error.missing.password') as string);
    }
    if (password !== confirmPasswordData) {
      setConfirmPasswordError(t('error.errorNotSamePassword') as string);
    }

    if (
      code &&
      password &&
      confirmPasswordData &&
      password === confirmPasswordData
    ) {
      (async () => {
        try {
          await confirmResetPassword({username: email.toLocaleLowerCase(), confirmationCode: code as string, newPassword: password as string});
          navigate('/login');
        } catch (err: Error | any) {
          console.log(err);
          setCodeError(CognitoErrorHandler(err, t));
        }
      })();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          {codeSent ? (
            <>
              <Typography component="h1" variant="h5">
                {t('page.forgotPassword.title2') as string}
              </Typography>
              <Typography variant="body2">
                {t('page.forgotPassword.description2') as string}
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmitConfirmPassword}
                noValidate
                sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label={t('label.code') as string}
                  name="code"
                  type="code"
                  autoComplete="code"
                  autoFocus
                  error={codeError !== ''}
                  helperText={codeError}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label={t('label.newPassword') as string}
                  name="password"
                  type="password"
                  autoComplete="password"
                  autoFocus
                  error={passwordError !== ''}
                  helperText={passwordError}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="confirmPassword"
                  label={t('label.confirmNewPassword') as string}
                  name="confirmPassword"
                  type="password"
                  autoComplete="confirmPassword"
                  autoFocus
                  error={confirmPasswordError !== ''}
                  helperText={confirmPasswordError}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}>
                  {t('label.changePassword') as string}
                </Button>
                <Button
                  onClick={() => navigate('/login')}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}>
                  {t('label.back') as string}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography component="h1" variant="h5">
                {t('page.forgotPassword.title1') as string}
              </Typography>
              <Typography variant="body2">
                {t('page.forgotPassword.description1') as string}
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmitForgotPassword}
                noValidate
                sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="mail"
                  label={t('label.mail') as string}
                  name="mail"
                  type="mail"
                  autoComplete="mail"
                  autoFocus
                  error={mailError !== ''}
                  helperText={mailError}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}>
                  {t('label.sendCode') as string}
                </Button>
                <Button
                  onClick={() => navigate('/login')}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}>
                  {t('label.back') as string}
                </Button>
              </Box>
            </>
          )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
