import React, { useEffect } from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import MuiDrawer from '@mui/material/Drawer';
import { Outlet, useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ReportIcon from '@mui/icons-material/Report';
import ListItemText from '@mui/material/ListItemText';
import {
  AccountCircle,
  Assignment,
  Settings,
  ExitToApp,
  Person,
  AdminPanelSettings,
  BarChart
} from '@mui/icons-material';
import { CircularProgress, Divider } from '@mui/material';
import List from '@mui/material/List';
import { isAdminSelector } from '../store/selectors';
import { useSelector } from 'react-redux';
import { setAdmin, setQuestion, setSite } from '../store/slices';
import { useDispatch } from 'react-redux';
import { useGetAdminMutation, useGetQueryMutation, useGetQuestionsByPhaseMutation, useGetRuleMutation, useGetSiteMutation } from '../store/api';
import { useTranslation } from 'react-i18next';
import { Colors } from '../styles/colors';
import { AdminRole } from '../types/types';
import QrCodeIcon from '@mui/icons-material/QrCode';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { fetchUserAttributes, signOut } from 'aws-amplify/auth/cognito';
import { useAppContext } from '../components/authentication/account';
import Error from '../components/error/error';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function Root() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userHasAuthenticated, setIsLoading, setIsError, isLoading, isError } = useAppContext();
  const user = useSelector(isAdminSelector);
  const [getAdmin] = useGetAdminMutation();
  const [getQuestions] = useGetQuestionsByPhaseMutation();
  const [getSite] = useGetSiteMutation();
  const [getRule] = useGetRuleMutation();
  const [getQuery] = useGetQueryMutation();

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const listItem = [
    {
      id: '0',
      items: [
        {
          id: 'dashboard',
          label: t('page.root.dashboardLabel') as string,
          icon: <Assignment />,
          function: () => navigate('/root/dashboard'),
          role: [AdminRole.Admin, AdminRole.Tec, AdminRole.Investigator, AdminRole.Arc, AdminRole.TecPrelev],
        },
        {
          id: 'patient',
          label: t('page.root.patientLabel') as string,
          icon: <Person />,
          function: () => navigate('/root/patient'),
          role: [AdminRole.Admin, AdminRole.Tec, AdminRole.Investigator, AdminRole.Arc, AdminRole.TecPrelev],
        },
        {
          id: 'admin',
          label: t('page.root.adminLabel') as string,
          icon: <AdminPanelSettings />,
          function: () => navigate('/root/admin'),
          role: [AdminRole.Admin],
        },
        {
          id: 'kit',
          label: 'Paramètre kit',
          icon: <QrCodeIcon />,
          function: () => navigate('/root/analyse-kit'),
          role: [AdminRole.Admin, AdminRole.Arc],
        },
        {
          id: 'data',
          label: 'Analyse des données',
          icon: <BarChart />,
          function: () => navigate('/root/data'),
          role: [AdminRole.Admin],
        },
        {
          id: 'query',
          label: t('page.root.queryLabel') as string,
          icon: <ReportIcon />,
          function: () => navigate('/root/query'),
          role: [AdminRole.Admin, AdminRole.Tec, AdminRole.Investigator, AdminRole.Arc, AdminRole.TecPrelev],
        },
      ],
    },
    {
      id: '1',
      items: [
        {
          id: 'document',
          label: 'Document',
          icon: <PictureAsPdfIcon />,
          function: () => navigate('/root/document'),
          role: [AdminRole.Admin, AdminRole.Tec, AdminRole.Investigator, AdminRole.Arc, AdminRole.TecPrelev],
        },
        {
          id: 'settings',
          label: t('page.root.settingsLabel') as string,
          icon: <Settings />,
          function: () => navigate('/root/settings'),
          role: [AdminRole.Admin, AdminRole.Tec, AdminRole.Investigator, AdminRole.Arc, AdminRole.TecPrelev],
        },
        {
          id: 'disconnect',
          label: t('page.root.logout') as string,
          icon: <ExitToApp />,
          function: async () => {
            userHasAuthenticated(false);
            await signOut();
          },
          role: [AdminRole.Admin, AdminRole.Tec, AdminRole.Investigator, AdminRole.Arc, AdminRole.TecPrelev],
        },
      ],
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const attributes = await fetchUserAttributes();
        const adminId = attributes.sub;
        const adminEmail = attributes.email;
  
        if (adminId && adminEmail) {
          const [adminResponse, questionsResponse, sitesResponse, rulesResponse, queryResponse] = await Promise.all([
            getAdmin({ id: adminId as string }).unwrap(),
            getQuestions().unwrap(),
            getSite().unwrap(),
            getRule().unwrap(),
            getQuery().unwrap()
          ]);
  
          dispatch(
            setAdmin({
              adminId: adminId,
              site: adminResponse.site,
              admin_role: adminResponse.admin_role,
              first_name: adminResponse.first_name,
              last_name: adminResponse.last_name,
              email: adminEmail,
              admins: [],
              adminRules: rulesResponse,
              query: queryResponse
            })
          );
  
          if (questionsResponse) {
            dispatch(setQuestion(questionsResponse));
          }
  
          if (sitesResponse) {
            dispatch(setSite(sitesResponse));
          }
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false)
      }
    };
  
    fetchData();
  }, [dispatch, getAdmin, getQuery, getQuestions, getRule, getSite, setIsError, setIsLoading]);
  

  

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    eventFunction: () => void
  ) => {
    eventFunction();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Pineappl data
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        hidden={window.innerWidth < 700 && open === false ? true : false}
        variant="permanent"
        open={open}>
        <DrawerHeader>
          <AccountCircle
            fontSize="large"
            sx={{
              mr: 3,
            }}
          />
          <ListItemText>
            {user.first_name} {user.last_name}
          </ListItemText>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {listItem.map((itemCategory) => (
          <Box key={itemCategory.id}>
            <List>
              {itemCategory.items.filter(item => item.role.includes(user.admin_role)).map((item) => (
                <ListItem
                  key={item.id}
                  disablePadding
                  sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    onClick={(event) =>
                      handleListItemClick(event, item.function)
                    }>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.label}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
          </Box>
        ))}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: Colors.mainBackgroundColor,
          minHeight: '100vh',
          height: '100%'
        }}>
        <DrawerHeader />
        { isError ? <Error /> : isLoading ?<Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px'}}><CircularProgress /></Box> : <Outlet />}
      </Box>
    </Box>
  );
}
