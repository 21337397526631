import React from 'react';
import Box from '@mui/material/Box';
import CircularProgressWithLabel from './circular-progress-with-label';

interface Props {
  progress: number;
  title?: string;
  taskCompleted?: number;
  task?: number;
}
const ProgressTask: React.FC<Props> = ({ progress, title, taskCompleted, task }) => {
  return (
    <Box sx={{ backgroundColor: '#f2f2f2', borderRadius: 5, height: '100%', padding: 2, display: 'flex', flexDirection: 'row' }}>
      <Box sx={{flex: 1, marginRight: 1}}><CircularProgressWithLabel value={progress} /></Box>
      <Box sx={{flex: 5}}>
        <h5>{title}</h5>
      </Box>
      <h6 style={{alignSelf: 'flex-end', textAlign: 'end', flex: 1}}>{taskCompleted}/{task}</h6>
    </Box>
  );
}


export default ProgressTask;
