import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IconButton, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {  adminSelector } from '../store/selectors';
import { useDeleteAdminMutation, useGetAdminsMutation } from '../store/api';
import { setAdmins } from '../store/slices';
import { Delete, Refresh } from '@mui/icons-material';
import CreateAdmin from '../components/form/create-admin';
import { useAppContext } from '../components/authentication/account';
import HandleLoadingError from '../components/handle-loading-error/handle-loading-error';


export default function AdminsPage() {
  const dispatch = useDispatch();
  const { setIsError } = useAppContext();
  const adminId = useSelector(adminSelector).adminId;
  const admins = useSelector(adminSelector).admins;
  const [getAdmins] = useGetAdminsMutation();
  const [
    deleteAdmin,
    { isLoading: isLoadingAdmin },
  ] = useDeleteAdminMutation();
  const width = window.innerWidth;

  const [filter, setFilter] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilter(event.target.value);
  };

  const handleDeleteDefinitivelyAdmin = async (id: string) => {
    try {
      await deleteAdmin({id}).unwrap()
      if (adminId) {
        const admins = await getAdmins().unwrap();
        dispatch(
          setAdmins(admins)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (adminId) {
          const admins = await getAdmins().unwrap();
          dispatch(
            setAdmins(admins)
          );
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [adminId, dispatch, getAdmins, setIsError])

  const handleCreateAdmin = async () => {
    try {
      if (adminId) {
        const admins = await getAdmins().unwrap();
        dispatch(
          setAdmins(admins)
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: width * 0.09 },
    { field: 'first_name', headerName: 'Prénom', width: width * 0.08  },
    { field: 'last_name', headerName: 'Nom', width: width * 0.08  },
    { field: 'admin_role', headerName: 'Role', width: width * 0.06  },
    { field: 'site', headerName: 'Site', width: width * 0.07  },
    { field: 'email', headerName: 'Email', width: width * 0.16  },
    {
      field: 'delete',
      headerName: 'Supprimer',
      width: width * 0.06,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleDeleteDefinitivelyAdmin(params.id as string)}><Delete/></IconButton>
        );
      }
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: 3,
      }}>
      <HandleLoadingError isError={false} isLoading={isLoading}>
        <h1>Admins</h1>
        <Box sx={{display: 'flex', flexDirection: 'row'}}>
          <Box sx={{flex: 2}}>
            <Box sx={{marginY: 2, display: 'flex', align: 'center'}}>
              <IconButton onClick={async () => {
                try {
                  if (adminId) {
                    const admins = await getAdmins().unwrap();
                    dispatch(
                      setAdmins(admins)
                    );
                  }
                } catch (error) {
                  console.error(error);
                }
              }} size='small' sx={{background: 'white', borderRadius: 2, marginRight: 2}}><Refresh fontSize='large' /></IconButton>
              <TextField size='small' id="standard-basic" label="Chercher un admin" variant="outlined" onChange={handleChange} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                backgroundColor: 'white',
                boxShadow: 1,
                borderRadius: 5,
                paddingBottom: 2,
              }}
            ><DataGrid
                rows={filter !== '' ? admins.filter(admin => admin.id.includes(filter)) : admins}
                loading={isLoading || isLoadingAdmin}
                columns={columns}
                sx={{
                  height: admins.length === 0 ? 400 : 'auto',
                  border: 'none'
                }}
                pageSizeOptions={[10]}
              />
            </Box>
          </Box>
          <Box sx={{flex: 1}}>
            <CreateAdmin onSubmit={handleCreateAdmin} />
          </Box>
        </Box>
      </HandleLoadingError>
    </Box>
  );
}
