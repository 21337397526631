import Box from '@mui/material/Box';
import { useGetQueryMutation, usePostQueryAnswerMutation } from '../store/api';
import { useEffect, useState } from 'react';
import { Query } from '../types/types';
import { useDispatch, useSelector } from 'react-redux';
import { isAdminSelector } from '../store/selectors';
import { setAdminQuery } from '../store/slices';
import { Button, Card, CardActions, CardContent, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import QueryItem from '../components/card/query_item';

export default function QueryPage() {
  const dispatch = useDispatch();
  const query = useSelector(isAdminSelector).query;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    answer: '',
  });

  const [
    getQuery,
    { isLoading: isLoadingGetQuery},
  ] = useGetQueryMutation();

  const [
    postQueryAnswer,
    { isLoading: isLoadingPostQueryAnswer },
  ] = usePostQueryAnswerMutation();

  useEffect(() => {
    const Start = async () => {
      try {
        setIsLoading(true);       
        const [localQuery] = await Promise.all([
          getQuery().unwrap(),
        ]);
  
        dispatch(setAdminQuery(localQuery.sort((a, b) => b.timestamp - a.timestamp)))
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false);
      }
    };
  
    Start();
  }, [dispatch, getQuery]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: 3,
      }}>
      <h1>Admins</h1>
      <Grid container spacing={2}>
        {
          query.map(item => (
            <QueryItem key={item.id} item={item} />
          ))
        }
      </Grid>
    </Box>
  );
}
